/*eslint-disable*/
import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const useStyles = makeStyles(styles);

const codeExample = `import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter/prism';
import { prism } from 'react-syntax-highlighter/styles/prism';
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const useStyles = makeStyles(styles);

export default function Example(){
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [multipleSelect, setMultipleSelect] = React.useState([]);
  const handleSimple = event => {
    setSimpleSelect(event.target.value);
  };
  const handleMultiple = event => {
    setMultipleSelect(event.target.value);
  };
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={6} md={5} lg={5}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
          >
            Single Select
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={simpleSelect}
            onChange={handleSimple}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
            <MenuItem
              disabled
              classes={{
                root: classes.selectMenuItem
              }}
            >
              Single Select
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="2"
            >
              Paris
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="3"
            >
              Bucharest
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="4"
            >
              Rome
            </MenuItem>
          </Select>
        </FormControl>
      </GridItem>
      <GridItem xs={12} sm={6} md={5} lg={5}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel
            htmlFor="multiple-select"
            className={classes.selectLabel}
          >
            Multiple Select
          </InputLabel>
          <Select
            multiple
            value={multipleSelect}
            onChange={handleMultiple}
            MenuProps={{
              className: classes.selectMenu,
              classes: { paper: classes.selectPaper }
            }}
            classes={{ select: classes.select }}
            inputProps={{
              name: "multipleSelect",
              id: "multiple-select"
            }}
          >
            <MenuItem
              disabled
              classes={{
                root: classes.selectMenuItem
              }}
            >
              Multiple Select
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelectedMultiple
              }}
              value="2"
            >
              Paris
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelectedMultiple
              }}
              value="3"
            >
              Bucharest
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelectedMultiple
              }}
              value="4"
            >
              Rome
            </MenuItem>
          </Select>
        </FormControl>
      </GridItem>
    </GridContainer>
  );
}`;

export default function Selects() {
  const [simpleSelect, setSimpleSelect] = React.useState("");
  const [multipleSelect, setMultipleSelect] = React.useState([]);
  const handleSimple = event => {
    setSimpleSelect(event.target.value);
  };
  const handleMultiple = event => {
    setMultipleSelect(event.target.value);
  };
  const classes = useStyles();
  return (
    <div>
      <h1>Select</h1>
      <p>
        We've decided to leave this component as is from{" "}
        {
          " "
          // eslint-disable-next-line
        }
        <a
          href="https://material-ui-next.com/demos/selects/?ref=creativetim"
          target="_blank"
        >
          material-ui selects component
        </a>{" "}
        and just add our style to them.
      </p>
      <h2>Styles</h2>
      <p>
        You will find the styles for this component in
        <br />{" "}
        <code>
          src/assets/jss/material-dashboard-pro-react/customSelectStyle.js
        </code>
        .
      </p>
      <h2>Example</h2>
      <GridContainer>
        <GridItem xs={12} sm={6} md={5} lg={5}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Single Select
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={simpleSelect}
              onChange={handleSimple}
              inputProps={{
                name: "simpleSelect",
                id: "simple-select"
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Single Select
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="2"
              >
                Paris
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="3"
              >
                Bucharest
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected
                }}
                value="4"
              >
                Rome
              </MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={6} md={5} lg={5}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="multiple-select"
              className={classes.selectLabel}
            >
              Multiple Select
            </InputLabel>
            <Select
              multiple
              value={multipleSelect}
              onChange={handleMultiple}
              MenuProps={{
                className: classes.selectMenu,
                classes: { paper: classes.selectPaper }
              }}
              classes={{ select: classes.select }}
              inputProps={{
                name: "multipleSelect",
                id: "multiple-select"
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                Multiple Select
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelectedMultiple
                }}
                value="2"
              >
                Paris
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelectedMultiple
                }}
                value="3"
              >
                Bucharest
              </MenuItem>
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelectedMultiple
                }}
                value="4"
              >
                Rome
              </MenuItem>
            </Select>
          </FormControl>
        </GridItem>
      </GridContainer>
      <SyntaxHighlighter language="jsx" style={prism}>
        {codeExample}
      </SyntaxHighlighter>
    </div>
  );
}
