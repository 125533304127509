import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { useHistory } from "react-router-dom";
import AdminService from "services/admin.service";
import ReactTable from "components/ReactTable/ReactTable.js";
import useSound from "use-sound";
import clickSound from "../../assets/sound/ui-click.wav";
import messageSound from "../../assets/sound/notification-up.wav";
import SweetAlert from "react-bootstrap-sweetalert";
import AddMasterModal from "../../components/Masters/AddMasterModal";
import {Group} from "@material-ui/icons";

const useStyles = makeStyles(styles);

// eslint-disable-next-line no-unused-vars
const Masters = () => {
  const classes = useStyles();
  const history = useHistory();
  const [openAmm, setAddMasterModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const handleCloseModal = () => {
      setAddMasterModal(false);
  };
  const [playClick] = useSound(clickSound);
  const [playMessage] = useSound(messageSound);
  const [alert, setAlert] = useState(null);
  const hideAlert = () => {
    setAlert(null);
  };
  const [masters, setMasters] = useState([]);

  useEffect(() => {

      AdminService.getMasters()
      .then((response) => {
        if (response.data.data.length === 0) {
          setTableLoading(false);
        }

        const data = response.data.data.map((prop) => {
          setTableLoading(false);

          const item = prop.attributes;
          const catTitles = item.category_titles.map((itemCat)=>{
              return  <span
                  style={{
                      marginLeft:"2px",
                      backgroundColor:"#" + itemCat.color,
                      color:"#ffffff",
                      borderRadius:"10px",
                      padding:"2px 5px",
                      fontSize:"10px",
                      fontWeight:"bold",
                      whiteSpace:"nowrap"
                  }}
                  key={itemCat.id}>{itemCat.title}
              </span>
          });
          return {
            id: prop.id,
            name: item.name,
            phone: item.phone,
            categories: catTitles,
            actions: (
              <div style={{ textAlign: "center" }}>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    playClick();
                    history.push("/admin/masters/edit/" + prop.id);
                  }}
                  color="success"
                  className={classes.actionButton}
                >
                  <Edit />
                </Button>{" "}
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    playClick();
                    setAlert(
                      <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Вы уверены?"
                        onConfirm={() =>
                          AdminService.deleteMaster(prop.id)
                            .then((response) => {
                              if (response.status === 204) {
                                playMessage();
                                setAlert(
                                  <SweetAlert
                                    success
                                    style={{
                                      display: "block",
                                      marginTop: "-100px",
                                    }}
                                    title="Удалили успешно!"
                                    onConfirm={() => hideAlert()}
                                    onCancel={() => hideAlert()}
                                    confirmBtnCssClass={classes.btnSuccess}
                                  ></SweetAlert>
                                );
                                var newData = data;
                                newData.find((o, i) => {
                                  if (o.id === prop.id) {
                                    newData.splice(i, 1);
                                    return true;
                                  }
                                  return false;
                                });
                                setMasters([...newData]);
                              }
                            })
                            .catch((error) => {
                              alert(error);
                            })
                        }
                        onCancel={() => hideAlert()}
                        confirmBtnCssClass={classes.btnSuccess}
                        cancelBtnCssClass={classes.btnRose}
                        confirmBtnText="Да, давай удалим!"
                        cancelBtnText="Нет"
                        showCancel
                      >
                        Будет удален сотрудник {item.name}
                      </SweetAlert>
                    );
                  }}
                  color="danger"
                  className={classes.actionButton}
                >
                  <Close />
                </Button>{" "}
              </div>
            ),
          };
        });

        setMasters(data);
      })
      .catch((error) => {
        setTableLoading(false);
        console.log(error);
      });
  }, []); //eslint-disable-line react-hooks/exhaustive-deps


   console.log(masters[0]);
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Group />
            </CardIcon>
            <div className={classes.addButtonContainer}>
              <Button
                onClick={() => {
                  setAddMasterModal(true);
                }}
                color="primary"
              >
                Добавить сотрудника
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <hr />

            {
              <ReactTable
                loading={tableLoading}
                columns={[
                  {
                    Header: "Имя",
                    accessor: "name",
                  },
                  {
                    Header: "Телефон",
                    accessor: "phone",
                  },
                    {
                        Header: "Категория",
                        accessor: "categories",
                    },
                  {
                    // eslint-disable-next-line react/display-name
                    Header: () => (
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        Действия
                      </div>
                    ),
                    accessor: "actions",
                  },
                ]}
                data={masters}
              />
            }

            <AddMasterModal open={openAmm} onClose={handleCloseModal} />
          </CardBody>
        </Card>
      </GridItem>
      {alert}
    </GridContainer>
  );
};

export default Masters;
