import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomInputDateTime from "components/CustomInput/CustomInputDateTime.js";
import CustomInputPhone from "components/CustomInput/CustomInputPhone.js";
import moment from 'moment';
import useSound from "use-sound";
import clickSound from "../../assets/sound/ui-click.wav";
import messageSound from "../../assets/sound/notification-up.wav";
import AdminService from "../../services/admin.service";
import Snackbar from "../Snackbar/Snackbar";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const AddClientModal = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const {open, onClose} = props;

    const [name, setName] = useState("");
    const [nameState, setNameState] = useState("");

    const [email, setEmail] = useState("");
    const [emailState, setEmailState] = useState("");

    const [phone, setPhone] = useState("");
    const [phoneState, setPhoneState] = useState("");

    const [socials, setSocials] = useState("");
    const [socialsState, setSocialsState] = useState("");

    const [dob, setDob] = useState({});
    const [dobState, setDobState] = useState("");


    const verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };

    const verifyDigitsCount = (value, length) => {
        return value.replace(/[^0-9]/g, "").length === length;
    }
    const onChangeName = (e) => {
        if (verifyLength(e.target.value, 1)) {
            setNameState("success");
        } else {
            setNameState("error");
        }
        setName(e.target.value);

    };
    const onChangeEmail = (e) => {

        setEmailState("success");

        setEmail(e.target.value);

    };
    const onChangePhone = (e) => {
        if (verifyDigitsCount(e.target.value, 1)) {
            setPhoneState("default");
        } else if (verifyDigitsCount(e.target.value, 11)) {
            setPhoneState("success");
        } else {
            setPhoneState("error");
        }
        setPhone(e.target.value.replace(/[^0-9]/g, "").substring(1));

    };

    const onChangeDob = (e) => {
        if (e === null || e === "") {
            setDobState("error");
        } else {
            if (typeof e === 'object') {
                setDobState("success");
                

            } else {
                setDobState("error");
            }
        }
        setDob(moment(e).format("YYYY-MM-DD"));

    };

    const onChangeSocials = (e) => {
        if (verifyLength(e.target.value, 1)) {
            setSocialsState("success");

        } else {
            setSocialsState("error");
        }
        setSocials(e.target.value);

    };
    const makeUid = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

   

    const [playClick] = useSound(clickSound);
    const [playMessage] = useSound(messageSound);
    const [notificationMessage, setMessage] = useState("");
    const [notificationBg, setMessageBg] = useState("warning");
    const [tr, setTR] = useState(false);
    const showNotification = () => {
        setTR(true);
        setTimeout(function() {
            setTR(false);
        }, 6000);
    }

    const handleAddClient = (e) => {
        e.preventDefault();

        if (name === "") {
            setNameState("error");
        }
        if (verifyDigitsCount(phone, 11) === false) {
            setPhoneState("error");
        }
        if (dob === "" || dob === null || typeof dob === 'object' ) {
            setDobState("error");
        }
       

        if(
            nameState === "success" &&
            phoneState === "success" &&
            dobState === "success"
        ){  
            var insta1 = '{"instagram":';
            var insta =  insta1+ '"'+ socials +'"}';   
            var data = {
               
                    "type": "clients",
                    "attributes": {
                        "uid":makeUid(15),
                        "name": name,
                        "email": email,
                        "dob":dob,
                        "phone":phone,
                        "socials": insta
                     }
               
            };
            //console.log(data);
            AdminService.addClient(data)
                .then(
                    (response)=>{

                        setName("");
                        setNameState("default");
                        setEmail("");
                        setEmailState("default");
                        setPhone("");
                        setPhoneState("default");
                        setDob("");
                        setDobState("default");
                        setSocials("");
                        setSocialsState("default");
                        playMessage();
                        setMessage("ок");
                        setMessageBg('success');
                        showNotification("tr");
                        history.push('/admin/clients/edit/'+ response.data.data.id)
                        
                    }
                )
                .catch((error) => {

                        // var message = 'Неизвесная ошибка';
                        // var e = error;
                        // if(e.toString() !== 'Error: Network Error'){
                        //
                        //     if(error.response.data.error.name){
                        //
                        //         message = error.response.data.error.name['0'];
                        //     }
                        //     else if(error.response.data.error.email){
                        //         message = error.response.data.error.email['0'];
                        //     }
                        //     else if(error.response.data.error.phone){
                        //         message = error.response.data.error.phone['0'];
                        //     }
                        //     else if(error.response.data.error.dob){
                        //         message = error.response.data.error.dob['0'];
                        //     }
                        //     else if(error.response.data.error.socials){
                        //         message = error.response.data.error.socials['0'];
                        //     }
                        //     else{
                        //         message = error.response.data.error;
                        //     }
                        //
                        // }
                        // else{
                        //     message = e.toString();
                        // }
                        //
                        // playMessage();
                        // setMessage(message);
                        // setMessageBg('danger');
                        // showNotification("tr");
                        alert(error);
                    }
                );
        }
        else{
            playMessage();
            setMessage("Не заполнены обязательные поля");
            setMessageBg('rose');
            showNotification("tr");
        }
    }


    return (
        <Dialog
            classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal
            }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="notice-modal-slide-title"
            aria-describedby="notice-modal-slide-description"
            fullWidth
        >
            <DialogTitle
                id="notice-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
                style={{textAlign: "left"}}
            >
                <span className={classes.cardTitle}>Добавить клиента</span>
                <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={onClose}
                >
                    <Close className={classes.modalClose}/>
                </Button>

            </DialogTitle>
            <DialogContent
                id="notice-modal-slide-description-2"
                className={classes.modalBody}
            >
                <CardBody>
                    <form onSubmit={handleAddClient}>
                        <div style={{marginTop: "20px"}}>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <CustomInput
                                    labelText="ФИО"
                                    id="name"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    success={nameState === "success"}
                                    error={nameState === "error"}
                                    default={nameState === "default"}
                                    inputProps={{
                                        autoComplete: "off",
                                        value: name,
                                        onChange: onChangeName,
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <CustomInput
                                    labelText="Email"
                                    id="email"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    success={emailState === "success"}
                                    error={emailState === "error"}
                                    default={emailState === "default"}
                                    inputProps={{
                                        autoComplete: "off",
                                        value: email,
                                        onChange: onChangeEmail,
                                    }}
                                />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>

                                <CustomInputPhone
                                    mask="+7(999)999-99-99"
                                    labelText="Телефон"
                                    id="phone"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    onChange={onChangePhone}
                                    success={phoneState === "success"}
                                    error={phoneState === "error"}
                                    default={phoneState === "default"}
                                    inputProps={{
                                        value:phone
                                    }}
                                />

                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>

                                <CustomInputDateTime
                                    labelText="Дата рождения"
                                    id="dob"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                   
                                    success={dobState === "success"}
                                    error={dobState === "error"}
                                    default={dobState === "default"}
                                    inputProps={{

                                        autoComplete: "off",
                                        onChange: onChangeDob,
                                        
                                        // onKeyDown: onKeyDownDob

                                    }}
                                />

                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <CustomInput
                                    labelText="Instagram"
                                    id="socials"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    success={socialsState === "success"}
                                    error={socialsState === "error"}
                                    default={socialsState === "default"}
                                    inputProps={{
                                        autoComplete: "off",
                                        value: socials,
                                        onChange: onChangeSocials,
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        <Button
                            color="rose"
                            type="submit"
                            onClick = {playClick}
                        >
                            Добавить клиента
                        </Button>
                    </div>
                    </form>
                </CardBody>


            </DialogContent>
            <Snackbar
                place="tr"
                color={notificationBg}
                message = {notificationMessage}
                open={tr}
                closeNotification={() => setTR(false)}
                close
            />
        </Dialog>
    )

}

export default AddClientModal;