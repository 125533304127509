import React from "react";
import {Redirect, Route} from "react-router-dom";

export const LockRoute = ({ component: Component, ...rest}) => (
  
    
    <Route
        {...rest}
        render={ props => 
             localStorage.getItem("lockUserData") != null
           
            ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname : "/auth/login",
                        state: {from: props.location}
                    }}
                />
            )

        }
    />
);