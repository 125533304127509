import React,{useState,useRef} from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import VpnKey from "@material-ui/icons/VpnKey";
//import AddAlert from "@material-ui/icons/AddAlert";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardAvatar from "components/Card/CardAvatar.js";

import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";

//import avatar from "assets/img/faces/marc.jpg";

import AuthService from "services/auth.service";
import AdminService from "services/admin.service";

//sound
import useSound from 'use-sound';
import clickSound from "assets/sound/ui-click.wav";
import messageSound from "assets/sound/notification-up.wav"
//notifications
import Snackbar from "components/Snackbar/Snackbar.js";


import defaultImage from "assets/img/default-avatar.png";

const useStyles = makeStyles(styles);

export default function Profile() {

  const user = AuthService.getCurrentUser();  
  const classes = useStyles();

  //обновление пароля
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordState, setNewPasswordState] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [newPasswordConfirmState, setNewPasswordConfirmState] = useState("");
  const onChangeNewPassword = (e) => {
    if (verifyLength(e.target.value, 8)) {
      setNewPasswordState("success");
    } else {
      setNewPasswordState("error");
    }
    setNewPassword(e.target.value);
    
  };
  const onChangeNewPasswordConfirm = (e) => {
    if (verifyLength(e.target.value, 8)) {
      setNewPasswordConfirmState("success");
    } else {
      setNewPasswordConfirmState("error");
    }
    setNewPasswordConfirm(e.target.value);    
  };
  const handleUpdatePassword = (e) => {
    e.preventDefault();
    
    if (newPassword === "") {
      setNewPasswordState("error");
    }
    if (newPasswordConfirm === "") {
      setNewPasswordConfirmState("error");
    }
    if(newPasswordState === "success" && newPasswordConfirmState ==="success"){
   
      AdminService.updatePassword(user.id, newPassword, newPasswordConfirm)
      .then(
        (response)=>{
          setNewPassword('');
          setNewPasswordState("default");
          setNewPasswordConfirm('');
          setNewPasswordConfirmState("default");
          playMessage();
          setMessage(response.data.success);
          setMessageBg('success');
          showNotification("tr"); 
        }      
      )
      .catch((error) => { 
     
        var message = 'Неизвесная ошибка';
        var e = error;
        if(e.toString() !== 'Error: Network Error'){
                
            if(error.response.data.error.newPassword){
              message = error.response.data.error.newPassword['0'];
            }
            else if(error.response.data.error.newPasswordConfirm){
              message = error.response.data.error.newPasswordConfirm['0'];
            }
            else{
              message = error.response.data.error;
            }  
            
          }
          else{
            message = e.toString();
          }
        
         playMessage();
         setMessage(message);
         setMessageBg('danger');
         showNotification("tr"); 
               
       }
      );
    }
}

//обновление профиля

const [name, setName] = useState(user.name);
const [nameState, setNameState] = useState("");

const [email, setEmail] = useState(user.email);
const [emailState, setEmailState] = useState("");

const [phone, setPhone] = useState(user.phone);
const [phoneState, setPhoneState] = useState("");


const onChangeName = (e) => {
  if (verifyLength(e.target.value, 1)) {
    setNameState("success");
  } else {
    setNameState("error");
  }
  setName(e.target.value);
  
};

const onChangeEmail = (e) => {
  if (verifyLength(e.target.value, 1)) {
    setEmailState("success");
  } else {
    setEmailState("error");
  }
  setEmail(e.target.value);
  
};
const onChangePhone = (e) => {
  if (verifyLength(e.target.value, 10)) {
    setPhoneState("success");
  } else {
    setPhoneState("error");
  }
  setPhone(e.target.value);
  
};
const handleUpdateProfile = (e) => {
  e.preventDefault();
 
  if (name === "") {
    setNameState("error");
  }
  if (email === "") {
    setEmailState("error");
  }
  if (phone === "") {
    setPhoneState("error");
  }
  if(nameState !== "error" && emailState !== "error" && phoneState !== "error"){
    
    AdminService.updateProfile(user.id, name, email,phone)
    .then(
      (response)=>{
        
        localStorage.setItem("user", JSON.stringify(response.data.user)); 
        setNameState('default');
        setEmailState('default');
        setPhoneState('default');  
        playMessage();
        setMessage(response.data.success);
        setMessageBg('success');
        showNotification("tr"); 
      }      
    )
    .catch((error) => { 
   
      var message = 'Неизвесная ошибка';
      var e = error;
      if(e.toString() !== 'Error: Network Error'){
           
          if(error.response.data.error.name){
           
            message = error.response.data.error.name['0'];
          }
          else if(error.response.data.error.email){
            message = error.response.data.error.email['0'];
          }
          else if(error.response.data.error.phone){
            message = error.response.data.error.phone['0'];
          }
          else{
            message = error.response.data.error;
          }  
          
        }
        else{
          message = e.toString();
        }
      
       playMessage();
       setMessage(message);
       setMessageBg('danger');
       showNotification("tr"); 
             
     }
    );
  }
}

//обновления авы
const [file, setFile] = useState(null);
const formRef = useRef(null)
const [imagePreviewUrl, setImagePreviewUrl] = useState(user.ava != null ? process.env.REACT_APP_AVA_URL+user.id+'/'+user.ava : defaultImage);
const handleImageChange = e => {
    e.preventDefault();
    let reader = new FileReader();
    let newFile = e.target.files[0];
    
    reader.onloadend = () => {
      setFile(newFile);
      setImagePreviewUrl(reader.result);
      //отправка формы
      formRef.current.dispatchEvent(new Event("submit"));
    };
    if (newFile) {
            
      reader.readAsDataURL(newFile);
      
    }
  };
const handleUpdateAva = (e) => {
    e.preventDefault();
  AdminService.updateAva(user.id, file)
 .then(
   (response)=>{     
     localStorage.setItem("user", JSON.stringify(response.data.user));
     playMessage();
     setMessage(response.data.success);
     setMessageBg('success');
     showNotification("tr"); 
   }      
 )
 .catch((error) => { 
    playMessage();
    setMessage(error.toString());
    setMessageBg('danger');
    showNotification("tr"); 
  }
 );
 
};
//звук и уведолмения
const [playClick] = useSound(clickSound);
const [playMessage] = useSound(messageSound);
const [notificationMessage, setMessage] = useState("");
const [notificationBg, setMessageBg] = useState("warning");
const [tr, setTR] = useState(false);
const showNotification = () => {
  setTR(true);
  setTimeout(function() {
    setTR(false);
  }, 6000);
}


  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
        <form onSubmit={handleUpdateProfile}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <PermIdentity />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Редактировать данные
              </h4>
            </CardHeader>
            <CardBody>
              
              
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="ФИО"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                    success={nameState === "success"}
                    error={nameState === "error"}
                    default={nameState === "default"}
                    inputProps={{
                      //value: user.name,
                      autoComplete: "off",
                      value:name,
                      onChange: onChangeName,
                    }}
                  />
                </GridItem>
             
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                    success={emailState === "success"}
                    error={emailState === "error"}
                    default={emailState === "default"}
                    inputProps={{
                      //value: user.email
                      autoComplete: "off",
                      value:email,
                      onChange: onChangeEmail,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Телефон"
                    id="phone"
                    formControlProps={{
                      fullWidth: true
                    }}
                    success={phoneState === "success"}
                    error={phoneState === "error"}
                    default={phoneState === "default"}
                    inputProps={{
                      //value: user.phone
                      autoComplete: "off",
                      value:phone,
                      onChange: onChangePhone,
                    }}
                  />
                </GridItem>
               
              </GridContainer>
              

              
              <Button 
              color="rose" 
              className={classes.updateProfileButton}
              type="submit"
              onClick = {playClick}
              >
                Обновить данные
              </Button>


              <Clearfix />
            </CardBody>
          </Card>
          </form>
        <form onSubmit={handleUpdatePassword}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <VpnKey />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                Смена пароля
              </h4>
            </CardHeader>
            <CardBody>            
              
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Новый пароль (мин. 8 символов)"
                    id="newPassword"
                    formControlProps={{
                      fullWidth: true
                    }}
                    success={newPasswordState === "success"}
                    error={newPasswordState === "error"}
                    default={newPasswordState === "default"}
                    inputProps={{
                      type: "password",
                      autoComplete: "off",
                      value:newPassword,
                      onChange: onChangeNewPassword,
                     
                    }}
                  />
                </GridItem>
             
              </GridContainer>

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Еще раз"
                    id="newPasswordConfirm"
                    formControlProps={{
                      fullWidth: true
                    }}
                    success={newPasswordConfirmState === "success"}
                    error={newPasswordConfirmState === "error"}
                    default={newPasswordState === "default"}
                    inputProps={{
                      type: "password",
                      autoComplete: "off",
                      value:newPasswordConfirm,
                      onChange: onChangeNewPasswordConfirm
                    }}
                  />
                </GridItem>
                
              </GridContainer>             

              
              <Button 
              color="rose" 
              className={classes.updateProfileButton}
              type="submit"
              onClick = {playClick}
              >
                Сменить пароль
              </Button>


              <Clearfix />
            </CardBody>
          </Card>
        </form>

        </GridItem>
        
        
        
        
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
           <CardAvatar > 
           <form onSubmit={handleUpdateAva} ref={formRef}>
            <div className="picture-container">
              <div className="picture">
                <img src={imagePreviewUrl} className="picture-src" alt="user"/>
                <input type="file" onChange={ handleImageChange}/>
              </div>             
            </div>
            </form>
            </CardAvatar>
            <CardBody profile>
              <h6 className={classes.cardCategory}>администратор</h6>
              <h4 className={classes.cardTitle}>{user.name}</h4>     
              
            </CardBody>
          </Card>
        </GridItem>
      
      </GridContainer>

      <Snackbar
                  place="tr"
                  color={notificationBg}                 
                  message = {notificationMessage}
                  open={tr}
                  closeNotification={() => setTR(false)}
                  close
                />
    </div>
  );
}
