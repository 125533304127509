/* eslint-disable */
import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Refresh from "@material-ui/icons/Refresh";
// core components
import Button from "components/CustomButtons/Button.js";

import { tooltip } from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  tooltip
};

const useStyles = makeStyles(styles);

const codeExample = `import React from "react";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from 'material-ui/Tooltip';
// @material-ui/icons
import Refresh from "@material-ui/icons/Refresh";
// core components
import Button from "components/CustomButtons/Button.js";

import {
  tooltip
} from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  tooltip
};

const useStyles = makeStyles(styles);

export default function Tooltips(){
  const classes = useStyles();
  return (
    <div>
      <Tooltip
        id="tooltip-top"
        title="Refresh"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <Button color="info" justIcon>
          <Refresh />
        </Button>
      </Tooltip>
    </div>
  );
}`;

export default function Tooltips() {
  const classes = useStyles();
  return (
    <div>
      <h1>Tooltips</h1>
      <p>
        We've restyled this component from Material-UI to give it a more
        attractive look, and to match the rest of the dashboard.
      </p>
      <p>Let's take a look at an example:</p>
      <h2>Example</h2>
      <Tooltip
        id="tooltip-top"
        title="Refresh"
        placement="top"
        classes={{ tooltip: classes.tooltip }}
      >
        <Button color="info" justIcon>
          <Refresh />
        </Button>
      </Tooltip>
      <SyntaxHighlighter language="jsx" style={prism}>
        {codeExample}
      </SyntaxHighlighter>
      <h2>Props</h2>
      <p>
        For more details please refer to{" "}
        <a href="https://material-ui-next.com/demos/tooltips/" target="_blank">
          material-ui
        </a>
        .
      </p>
    </div>
  );
}
