import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import useSound from "use-sound";
import clickSound from "../../assets/sound/ui-click.wav";
import messageSound from "../../assets/sound/notification-up.wav";
import AdminService from "../../services/admin.service";
import Snackbar from "../Snackbar/Snackbar";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import classNames from "classnames";


const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const AddServiceModal = (props) => {
  const classes = useStyles();
  const history = useHistory();
  // eslint-disable-next-line react/prop-types
  const { open, onClose } = props;
  const [successSelect, setSuccess] = useState(false);
  const [errorSelect, setError] = useState(false);
  const labelClasses = classNames({
    [" " + classes.labelRootError]: errorSelect,
    [" " + classes.labelRootSuccess]: successSelect && !errorSelect,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: errorSelect,
    [classes.underlineSuccess]: successSelect && !errorSelect,
    [classes.underline]: true,
    //[classes.whiteUnderline]: white,
  });

  // var helpTextClasses = classNames({
  //   [classes.labelRootError]: error,
  //   [classes.labelRootSuccess]: success && !error,
  // });

  const [title, setTitle] = useState("");
  const [titleState, setTitleState] = useState("");

  const [description, setDescription] = useState("");
  const [descriptionState, setDescriptionState] = useState("");

  const [price, setPrice] = useState("");
  const [priceState, setPriceState] = useState("");

  const [categoryData, setCategoryData] = useState([]);
  const [category, setCategory] = useState("");
  //const [categoryState, setCategoryState] = useState("");

  const verifyLength = (value, length) => {
    return value.length >= length;
  };

  const onChangeTitle = (e) => {
    if (verifyLength(e.target.value, 1)) {
      setTitleState("success");
    } else {
      setTitleState("error");
    }
    setTitle(e.target.value);
  };
  const onChangeDescription = (e) => {
    setDescriptionState("success");
    setDescription(e.target.value);
  };
  const onChangePrice = (e) => {
    if (verifyLength(e.target.value, 1)) {
      setPriceState("success");
    } else {
      setPriceState("error");
    }
    setPrice(e.target.value);
  };
  const onChangeCategory = (e) => {
    if (verifyLength(e.target.value, 1)) {
      setSuccess(true);
      //setCategoryState("success");
    } else {
      setError(true);
      //setCategoryState("error");
    }
    setCategory(e.target.value);
  };
  const [playClick] = useSound(clickSound);
  const [playMessage] = useSound(messageSound);
  const [notificationMessage, setMessage] = useState("");
  const [notificationBg, setMessageBg] = useState("warning");
  const [tr, setTR] = useState(false);
  const showNotification = () => {
    setTR(true);
    setTimeout(function () {
      setTR(false);
    }, 6000);
  };

  useEffect(() => {
    AdminService.getCategories()
      .then((response) => {
        const data = response.data.data.map((prop) => {
          const item = prop.attributes;
          return {
            id: prop.id,
            title: item.title,
          };
        });
        setCategoryData(data);
      })
      .catch((error) => {
        alert(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleAddService = (e) => {
    e.preventDefault();

    if (title === "") {
      setTitleState("error");
    }
    if (price === "") {
      setPriceState("error");
    }

    if (
      titleState === "success" &&
      descriptionState === "success" &&
      priceState === "success"
    ) {
      var data = {
        type: "services",
        attributes: {
          title: title,
          description: description,
          price: price,
          category_id: category,
        },
      };

      AdminService.addService(data)
        .then((response) => {
          setTitle("");
          setTitleState("default");
          setDescription("");
          setDescriptionState("default");
          setPrice("");
          setPriceState("default");
          playMessage();
          setMessage("ок");
          setMessageBg("success");
          showNotification("tr");
          history.push("/admin/services/edit/" + response.data.data.id);
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      playMessage();
      setMessage("Не заполнены обязательные поля");
      setMessageBg("rose");
      showNotification("tr");
    }
  };

  const categoriesList = categoryData.map((item) => {
    return <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        value={item.id}
    >
      {item.title}
    </MenuItem>
  })

  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
      fullWidth
    >
      <DialogTitle
        id="notice-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
        style={{ textAlign: "left" }}
      >
        <span className={classes.cardTitle}>Добавить услугу</span>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={onClose}
        >
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent
        id="notice-modal-slide-description-2"
        className={classes.modalBody}
      >
        <CardBody>
          <form onSubmit={handleAddService}>
            <div style={{ marginTop: "20px" }}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Название"
                    id="title"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    success={titleState === "success"}
                    error={titleState === "error"}
                    default={titleState === "default"}
                    inputProps={{
                      autoComplete: "off",
                      value: title,
                      onChange: onChangeTitle,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Описание"
                    id="description"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    success={descriptionState === "success"}
                    error={descriptionState === "error"}
                    default={descriptionState === "default"}
                    inputProps={{
                      autoComplete: "off",
                      value: description,
                      onChange: onChangeDescription,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth className={classes.formControl}>
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.labelRoot + " " + labelClasses}
                      style={{ marginTop: "5px" }}
                    >
                      Категория
                    </InputLabel>
                    <Select
                      className={underlineClasses}
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={category}
                      onChange={onChangeCategory}
                      inputProps={{
                        name: "simpleSelect",
                        id: "simple-select",
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem,
                        }}
                      >
                        Категория
                      </MenuItem>
                      {categoriesList}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Цена"
                    id="price"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    success={priceState === "success"}
                    error={priceState === "error"}
                    default={priceState === "default"}
                    inputProps={{
                      autoComplete: "off",
                      value: price,
                      onChange: onChangePrice,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Button color="rose" type="submit" onClick={playClick}>
                Добавить услугу
              </Button>
            </div>
          </form>
        </CardBody>
      </DialogContent>
      <Snackbar
        place="tr"
        color={notificationBg}
        message={notificationMessage}
        open={tr}
        closeNotification={() => setTR(false)}
        close
      />
    </Dialog>
  );
};

export default AddServiceModal;
