import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
//import Dashboard from "@material-ui/icons/Dashboard";
//import Search from "@material-ui/icons/Search";
import GiftCard from "@material-ui/icons/Redeem";
// core components
//import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

import AuthService from "services/auth.service";
import { useHistory, useLocation } from "react-router-dom";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const history = useHistory();
  const location = useLocation();
  const [openNotification, setOpenNotification] = React.useState(null);
  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };

  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const [openProfile, setOpenProfile] = React.useState(null);
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  const handleLogout = () => {
    AuthService.logout();
    history.push("/auth/login");
  };
  const handleLock = () => {
    const user = AuthService.getCurrentUser();

    localStorage.setItem(
      "lockUserData",
      JSON.stringify({
        username: user.email,
        name: user.name,
        ava: user.ava,
        id: user.id,
        location: location.pathname,
      })
    );

    localStorage.removeItem("user");

    history.push("/auth/lock");
  };
  const handleToProfile = () => {
    setOpenProfile(false);
    history.push("/admin/profile");
  };

  const classes = useStyles();
  const { rtlActive } = props;
  const updateNTStorage = (uid) => {
    NTData.forEach((item, index) => {
      if (item.uid === uid) {
        NTData.splice(index, 1);
        NTData.sort((a, b) => b.date.localeCompare(a.date));
      }
    });

    localStorage.setItem("notificationDataStorage", JSON.stringify(NTData));
  };

  const MarkIsRead = () => {
    setOpenNotification(null);
    localStorage.setItem("notificationDataStorage", JSON.stringify([]));
  };
  const NTData = JSON.parse(localStorage.getItem("notificationDataStorage"));
  const NTCount = JSON.parse(localStorage.getItem("notificationDataStorage"))
    .length;

  //console.log(NTData);
  const handleNotificationItemClick = (notificationItem) => {
    setOpenNotification(null);
    updateNTStorage(notificationItem.uid);
    history.push("/admin/clients/edit/" + notificationItem.clientId);
  };

  // const searchButton =
  //   classes.top +
  //   " " +
  //   classes.searchButton +
  //   " " +
  //   classNames({
  //     [classes.searchRTL]: rtlActive,
  //   });
  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  return (
    <div className={wrapper}>
      {/*<CustomInput*/}
      {/*  rtlActive={rtlActive}*/}
      {/*  formControlProps={{*/}
      {/*    className: classes.top + " " + classes.search,*/}
      {/*  }}*/}
      {/*  inputProps={{*/}
      {/*    placeholder: "Поиск",*/}
      {/*    inputProps: {*/}
      {/*      "aria-label": "Поиск",*/}
      {/*      className: classes.searchInput,*/}
      {/*    },*/}
      {/*  }}*/}
      {/*/>*/}
      {/*<Button*/}
      {/*  color="white"*/}
      {/*  aria-label="edit"*/}
      {/*  justIcon*/}
      {/*  round*/}
      {/*  className={searchButton}*/}
      {/*>*/}
      {/*  <Search className={classes.headerLinksSvg + " " + classes.searchIcon} />*/}
      {/*</Button>*/}

      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Notifications"
          aria-owns={openNotification ? "notification-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <Notifications
            className={classes.headerLinksSvg + " " + classes.links}
          />

          <Hidden mdUp implementation="css">
            <span
              onClick={handleClickNotification}
              className={classes.linkText}
            >
              {"Уведомления  "}
            </span>
          </Hidden>
          {NTCount ? (
            <span className={classes.notifications}>{NTCount}</span>
          ) : (
            ""
          )}
        </Button>
        <Popper
          open={Boolean(openNotification)}
          anchorEl={openNotification}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openNotification,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="notification-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseNotification}>
                  <MenuList
                    role="menu"
                    style={{ maxHeight: "250px", overflow: "auto" }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        padding: "0 10px",
                        marginBottom: "15px",
                      }}
                    >
                      {NTCount > 0 ? (
                        <Button simple color={"primary"} onClick={MarkIsRead}>
                          Отметить все, как прочитанное
                        </Button>
                      ) : (
                        <p>Новых уведомлений нет</p>
                      )}
                      <Divider light />
                    </div>

                    {NTCount > 0
                      ? NTData.map((notificationItem) => (
                          <MenuItem
                            onClick={() =>
                              handleNotificationItemClick(notificationItem)
                            }
                            className={dropdownItem}
                            key={notificationItem.uid}
                            id={notificationItem.uid}
                          >
                            {notificationItem.type === "birthday" ? (
                              <GiftCard />
                            ) : (
                              ""
                            )}
                            <br />
                            {notificationItem.text}
                            <br />
                            <span>{notificationItem.name}</span>
                            <br />
                            <span style={{ fontSize: "10px" }}>
                              {notificationItem.date}
                            </span>
                          </MenuItem>
                        ))
                      : ""}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>

      <div className={managerClasses}>
        <Button
          color="transparent"
          aria-label="Person"
          justIcon
          aria-owns={openProfile ? "profile-menu-list" : null}
          aria-haspopup="true"
          onClick={handleClickProfile}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : "",
          }}
        >
          <Person
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
          <Hidden mdUp implementation="css">
            <span onClick={handleClickProfile} className={classes.linkText}>
              {"Профиль администратора"}
            </span>
          </Hidden>
        </Button>
        <Popper
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openProfile,
            [classes.popperResponsive]: true,
            [classes.popperNav]: true,
          })}
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list"
              style={{ transformOrigin: "0 0 0" }}
            >
              <Paper className={classes.dropdown}>
                <ClickAwayListener onClickAway={handleCloseProfile}>
                  <MenuList role="menu">
                    <MenuItem
                      onClick={handleToProfile}
                      className={dropdownItem}
                    >
                      {"Профиль администратора"}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={dropdownItem}
                    >
                      {"Настройки"}
                    </MenuItem>
                    <Divider light />
                    <MenuItem onClick={handleLock} className={dropdownItem}>
                      {"Спящий режим"}
                    </MenuItem>
                    <MenuItem onClick={handleLogout} className={dropdownItem}>
                      {"Выход"}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
