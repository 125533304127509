/*!

=========================================================
* Material Kit PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";

const files = `components
  ├── Accordion
  │   └── Accordion.js
  ├── Badge
  │   └── Badge.js
  ├── Card
  │   ├── Card.js
  │   ├── CardAvatar.js
  │   ├── CardBody.js
  │   ├── CardFooter.js
  │   ├── CardHeader.js
  │   ├── CardIcon.js
  │   └── CardText.js
  ├── Clearfix
  │   └── Clearfix.js
  ├── CustomButtons
  │   └── Button.js
  ├── CustomDropdown
  │   └── CustomDropdown.js
  ├── CustomInput
  │   └── CustomInput.js
  ├── CustomLinearProgress
  │   └── CustomLinearProgress.js
  ├── CustomTabs
  │   └── CustomTabs.js
  ├── CustomUpload
  │   ├── ImageUpload.js
  │   └── PictureUpload.js
  ├── FixedPlugin
  │   ├── FixedPlugin.js
  │   └── FixedPlugin.js
  ├── Footer
  │   └── Footer.js
  ├── Grid
  │   ├── GridContainer.js
  │   └── GridItem.js
  ├── Heading
  │   └── Heading.js
  ├── InfoArea
  │   └── InfoArea.js
  ├── Instruction
  │   └── Instruction.js
  ├── NavPills
  │   └── NavPills.js
  ├── Navbars
  │   ├── AdminNavbar.js
  │   ├── AdminNavbarLinks.js
  │   └── AuthNavbar.js
  ├── Pagination
  │   └── Pagination.js
  ├── Sidebar
  │   └── Sidebar.js
  ├── Snackbar
  │   ├── Snackbar.js
  │   └── SnackbarContent.js
  ├── Table
  │   └── Table.js
  ├── Tasks
  │   └── Tasks.js
  ├── Timeline
  │   └── Timeline.js
  ├── Typography
  │   ├── Danger.js
  │   ├── Info.js
  │   ├── Muted.js
  │   ├── Primary.js
  │   ├── Quote.js
  │   ├── Success.js
  │   └── Warning.js
  └── Wizard
      └── Wizard.js`;

function Styles() {
  return (
    <div>
      <h1>Styles</h1>
      <p>
        Like our friends from{" "}
        <a href="https://material-ui.com/?ref=creativetim" target="_blank">
          Material UI
        </a>
        , we've used JSS syntax to style (restyle) the material ui components.
      </p>
      <p>
        A useful link about JSS is{" "}
        <a
          href="https://github.com/cssinjs/jss?ref=creativetim"
          target="_blank"
        >
          this one here
        </a>
        .
      </p>
      <p>
        We've decided to use the <code>makeStyles</code> function from
        Material-UI to override some of thier styles. This is a React HOOK
        functions, which means that it can only be called from another React
        HOOK or a simple function. Read more about it{" "}
        <a
          href="https://material-ui.com/styles/api/#makestyles-styles-options-hook"
          target="_blank"
        >
          here
        </a>
      </p>
      <p>
        We've also used <code>withStyles</code> function from Material-UI to
        override some of their styles. This was used for those components that
        could not be translated to hooks. Read more about it{" "}
        <a
          href="https://material-ui.com/styles/api/#makestyles-styles-options-hook"
          target="_blank"
        >
          here
        </a>
      </p>
      <p>
        All of the restyled components can be found in{" "}
        <code>src/components</code>.
      </p>
      <SyntaxHighlighter language="jsx" style={prism}>
        {files}
      </SyntaxHighlighter>
      <p>
        If you go to any of our components you will see that somewhere at the
        beginning of the file we import <code>makeStyles</code> from{" "}
        <code>material-ui</code>, then we import from{" "}
        <code>scr/assets/jss/*</code> the correspondig style for that component
        and after these imports, we use the <code>makeStyles</code> function
        alongside our styles to compile them to jss from javascript objects.
      </p>
      <p>
        There are some components in which you will see that somewhere at the
        beginning of the file we import <code>withStyles</code>
        from <code>material-ui</code>, then we import from{" "}
        <code>src/assets/jss/material-kit-pro-react/components</code> the
        correspondig style for that component and at the end of the file, we
        export that component's class/function with the <code>withStyles</code>
        function so that the imported style overrides the{" "}
        <code>material-ui</code> style.
      </p>
      <p>
        For a better way of understanding this variables, please take a look
        inside the <code>src/assets/jss/*</code>. You will see that all of the
        styles have a unique name that corresponds with the component name.
      </p>
      <p>
        Besides this styles, we have{" "}
        <code>src/assets/jss/material-kit-pro-react.js</code> with variables for
        styles, <code>src/assets/jss/material-kit-pro-react/views/*</code> used
        to style each page/view or add custom styles to our components on each
        view/page and <code>src/assets/jss/material-kit-pro-react/*.js</code>{" "}
        are styles for components that weren't extended by us, we're using them
        like material-ui, but with our styles.
      </p>
      <p>
        Beside this styles, because we've used some plugins in our demo, you
        will find some of our styles in <code>src/assets/scss</code>.
      </p>
      <p>
        Due to some complications we've had to keep in some components the{" "}
        <code>withStyles</code> functions from Material-UI.
      </p>
      <p>
        You can read more about it here:{" "}
        <a
          href="https://material-ui.com/guides/typescript/#usage-of-withstyles?ref=creativetim"
          target="_blank"
        >
          material-ui docs
        </a>
        .
      </p>
    </div>
  );
}

export default Styles;
