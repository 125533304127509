import React from "react";
import { Link } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";

class RoutingSystem extends React.Component {
  render() {
    return (
      <div>
        <h1 className="bd-title" id="content">
          Routing System
        </h1>
        <p>
          We've created these dynamic routes, so we wouldn't have to write in
          two places them. On place would have been inside our{" "}
          <Link to="/documentation/sidebar">Sidebar</Link> and the onther one
          would be either the{" "}
          <code className="highlighter-rouge">src/Auth.js</code> layout, the{" "}
          <code className="highlighter-rouge">src/Admin.js</code> layout or the{" "}
          <code className="highlighter-rouge">src/layouts/RTL.js</code> layout.
          You will find all our demo routes in{" "}
          <code className="highlighter-rouge">src/routes.js</code>
        </p>
        <h2 id="content">Legend</h2>
        <ol>
          <li>
            <SyntaxHighlighter language="jsx" style={prism}>
              {`{path: "/path-name", name: "Name Of the View", rtlName: "اسم العرض", icon: ..., component: ViewComponent, layout: "/layout-path"},`}
            </SyntaxHighlighter>
            <ul>
              <li>
                <code className="highlighter-rouge">path</code> (path for your
                route - this will be seen in the browser url input - example{" "}
                <code className="highlighter-rouge">/dashboard</code>)
              </li>
              <li>
                <code className="highlighter-rouge">name</code> (name of your
                route - this will appear in the{" "}
                <code className="highlighter-rouge">Sidebar</code> and{" "}
                <code className="highlighter-rouge">Header</code> components -
                example <code className="highlighter-rouge">لوحة القيادة</code>{" "}
                on the RTL layout)
              </li>
              <li>
                <code className="highlighter-rouge">rtlName</code> (name of your
                route - this will appear in the{" "}
                <code className="highlighter-rouge">Sidebar</code> and{" "}
                <code className="highlighter-rouge">Header</code> components -
                example <code className="highlighter-rouge">Dashboard</code>)
              </li>
              <li>
                <code className="highlighter-rouge">icon</code> (icon to be
                displayed alongside with links in{" "}
                <code className="highlighter-rouge">Sidebar</code> component -
                example <code className="highlighter-rouge">favorite</code> or{" "}
                <code className="highlighter-rouge">Favorite</code> from{" "}
                <code className="highlighter-rouge">@material-ui/icons</code>)
              </li>
              <li>
                <code className="highlighter-rouge">component</code> (this is
                the View component that you want to be displayed on the
                specified route - example{" "}
                <code className="highlighter-rouge">Dashboard</code>)
              </li>
              <li>
                <code className="highlighter-rouge">layout</code> (path of the
                layout in which the View component you want to be rendered - in
                our template demo you only have to options:{" "}
                <code className="highlighter-rouge">/admin</code> and{" "}
                <code className="highlighter-rouge">/auth</code> - but due to
                this routing system you can add more, for example{" "}
                <code className="highlighter-rouge">/new-layout</code>)
              </li>
            </ul>
          </li>
          <li>
            <SyntaxHighlighter language="jsx" style={prism}>
              {`{ collapse: true, name: "Name of the collapse group", rtlName: "اسم العرض", icon: ..., state: "string", views: [arrayOfRoutes]},`}
            </SyntaxHighlighter>
            <ul>
              <li>
                <code className="highlighter-rouge">collapse</code> (used to
                tell our deom app components this is a collapsible group - for{" "}
                <code className="highlighter-rouge">
                  src/components/Sidebar/Sidebar.js
                </code>
                ,
                <code className="highlighter-rouge">
                  src/layouts/Admin/Admin.js
                </code>{" "}
                and{" "}
                <code className="highlighter-rouge">
                  src/layouts/Auth/Auth.js
                </code>{" "}
                - you can only use it like so:{" "}
                <code className="highlighter-rouge">collapse: true</code>)
              </li>
              <li>
                <code className="highlighter-rouge">name</code> (name of
                collapsible group that is displayed in{" "}
                <code className="highlighter-rouge">
                  src/components/Sidebar/Sidebar.js
                </code>{" "}
                - example <code className="highlighter-rouge">Forms</code>)
              </li>
              <li>
                <code className="highlighter-rouge">rtlName</code> (name of
                collapsible group that is displayed in{" "}
                <code className="highlighter-rouge">
                  src/components/Sidebar/Sidebar.js
                </code>{" "}
                - example <code className="highlighter-rouge">إستمارات</code>,
                on the RTL layout)
              </li>
              <li>
                <code className="highlighter-rouge">state</code> (name of the
                state used in{" "}
                <code className="highlighter-rouge">
                  src/components/Sidebar/Sidebar.js
                </code>
                's state to know which collapsible is active/collapsed - based
                on these the state of the{" "}
                <code className="highlighter-rouge">
                  src/components/Sidebar/Sidebar.js
                </code>{" "}
                is created - exmaple{" "}
                <code className="highlighter-rouge">pagesCollapse</code>)
              </li>
              <li>
                <code className="highlighter-rouge">icon</code> (icon to be
                displayed alonside the name of the collapsible group inside the{" "}
                <code className="highlighter-rouge">Sidebar</code> component -
                example <code className="highlighter-rouge">favorite</code> or{" "}
                <code className="highlighter-rouge">Favorite</code> from{" "}
                <code className="highlighter-rouge">@material-ui/icons</code>)
              </li>
              <li>
                <code className="highlighter-rouge">views</code> (array of links
                that will be part of the collapsible group)
                <br />
                <code className="highlighter-rouge">arrayOfRoutes</code>
                <ol>
                  <li>
                    <SyntaxHighlighter language="jsx" style={prism}>
                      {`{ path: "/path-name", name: "Name Of the View", rtlName: "اسم العرض", mini: "NV", rtlMini: "ع", component: ViewComponent, layout: "/layout-path" },`}
                    </SyntaxHighlighter>
                  </li>
                  <li>
                    <SyntaxHighlighter language="jsx" style={prism}>
                      {`{ collapse: true, name: "Name of the collapse group", rtlName: "اسم العرض", mini: "NCG", rtlMini: "ر", state: "string", views: [...]},`}
                    </SyntaxHighlighter>
                  </li>
                </ol>
                <SnackbarContent
                  message={
                    <span>
                      The only difference for these, is that the{" "}
                      <code className="highlighter-rouge">icon</code> is not
                      anymore an icon name, it is mearly a text
                    </span>
                  }
                  color="info"
                />
              </li>
            </ul>
          </li>
        </ol>
        <p>
          For a better understanding, please take a look inside the file at
          hand, and also how the routes are rendered while the app si opened.
        </p>
        <h2 id="content">
          <code className="highlighter-rouge">Notice</code>
        </h2>
        <p>
          Because our routes are arrays of objects, and each route is an object,
          you can add what props you want in our routes and do what you want
          with them.
        </p>
        <p>
          For example, if you want to "hide" a route (you want it to not be
          displayed in sidebar), you could add a prop like{" "}
          <code className="highlighter-rouge">invisible: true</code> and then in
          sidebar add an if statement inside the{" "}
          <code className="highlighter-rouge">map</code> function of ours and do
          like this:
        </p>
        <SyntaxHighlighter language="jsx" style={prism}>
          {`if(prop.invisible) return null;`}
        </SyntaxHighlighter>
      </div>
    );
  }
}

export default RoutingSystem;
