import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
    tablePreloader: {
    textAlign:"center",
    margin:"50px auto"
  },
}));

const Circular = () => {
const classes = useStyles();

  return (
    <div className={classes.tablePreloader}>     
      <CircularProgress color="secondary" />
       <p>Загружаем данные...</p>  
    </div>
  );

}
export default Circular;