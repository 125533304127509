import React , {useState}from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardFooter from "components/Card/CardFooter.js";
import AddAlert from "@material-ui/icons/AddAlert";
//import avatar from "assets/img/faces/avatar.jpg";

import styles from "assets/jss/material-dashboard-pro-react/views/lockScreenPageStyle.js";

//notifications
import Snackbar from "components/Snackbar/Snackbar.js";
//sound
import useSound from 'use-sound';
import clickSound from "assets/sound/ui-click.wav";
import messageSound from "assets/sound/notification-up.wav"
//import AdminService from "services/admin.service";
import AuthService from "services/auth.service";
import {useHistory} from "react-router-dom";
import defaultImage from "assets/img/default-avatar.png";

const useStyles = makeStyles(styles);

export default function LockScreenPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem('lockUserData'));
  const [password, setPassword] = useState("");
  const [passwordState, setPasswordState] = useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const [tc, setTC] = React.useState(false);
  const showNotification = () => {
        setTC(true);
        setTimeout(function() {
          setTC(false);
        }, 10000);
      };
  const verifyLength = (value, length) => {
        if (value.length >= length) {
          return true;
        }
        return false;
  };    
  const onChangePassword = (e) => {
    
    if (verifyLength(e.target.value, 1)) {
      setPasswordState("success");
    } 
    else {
      setPasswordState("error");
    }
    setPassword(e.target.value);
    
  };
  const handleUnlock = (e) => {
    e.preventDefault();
    if (passwordState === "") {
      setPasswordState("error");
    }
    else if(passwordState ==="success"){
      
      AuthService.unLock(user.username, password).then(
        ()=>{
          //window.location.reload();
          history.push(user.location);
        }      
      )
      .catch((error) => { 
      
        var message = 'Неизвесная ошибка';
        var e = error;
        if(e.toString() !== 'Error: Network Error'){
                
            if(error.response.data.error.password){
              message = error.response.data.error.password['0'];
            }
            else{
              message = error.response.data.error;
            }  
            
          }
          else{
            message = e.toString();
          }
        
        playMessage();
        setErrorMessage(message);
        showNotification(); 
               
       }
      );
    }
    else{
      playMessage();
      setErrorMessage("Поле пароль обязательно");    
      showNotification();
    }


    
  }
  const [playClick] = useSound(clickSound);
  const [playMessage] = useSound(messageSound); 
  return (
    <div className={classes.container}>
      <form onSubmit={handleUnlock}>
        <Card
          profile
          className={classes.customCardClass + " " + classes[cardAnimaton]}
        >
          <CardAvatar profile className={classes.cardAvatar}>
           
              <img 
              src={user.ava != null ? 'http://app.sunplaza-kzn.ru/storage/users/ava/'+user.id+'/'+user.ava : defaultImage} 
              alt={user.name}
              style={{height:"90px",objectFit:"cover" }}
              />
            
          </CardAvatar>
          <CardBody profile>
         <h4 className={classes.cardTitle}>{user.name}</h4>
          
            <CustomInput
              labelText="Пароль"
              id="password"
              formControlProps={{
                fullWidth: true
              }}
              success={passwordState === "success"}
              error={passwordState === "error"}
              inputProps={{
                type: "password",
                autoComplete: "off",
                onChange:onChangePassword
              }}
            />
          </CardBody>
          <CardFooter className={classes.justifyContentCenter + " " + classes.customCardFooterClass}>
            <Button 
            color="rose" 
            round
            type="submit"
            onClick = {playClick}
            
            >
              Разблокировать
            </Button>
          </CardFooter>
        </Card>
      </form>
    
      <Snackbar
                  place="tc"
                  color="rose"
                  icon={AddAlert}
                  message = {errorMessage}
                  open={tc}
                  closeNotification={() => setTC(false)}
                  close
                />       
    </div>
  
              
  );
}
