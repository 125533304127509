/*!

=========================================================
* Material Dashboard PRO React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// Getting started
import Tutorial from "layouts/Documentation/Sections/Tutorial.js";
import RoutingSystem from "layouts/Documentation/Sections/RoutingSystem.js";
import Variables from "layouts/Documentation/Sections/Variables.js";
import Styles from "layouts/Documentation/Sections/Styles.js";
import Lint from "layouts/Documentation/Sections/Lint.js";
import BuildTools from "layouts/Documentation/Sections/BuildTools.js";

// Components
import Accordion from "layouts/Documentation/Sections/Accordion.js";
import Badge from "layouts/Documentation/Sections/Badge.js";
import Buttons from "layouts/Documentation/Sections/Buttons.js";
import Cards from "layouts/Documentation/Sections/Cards.js";
import CheckboxRadioSwitch from "layouts/Documentation/Sections/CheckboxRadioSwitch.js";
import Clearfix from "layouts/Documentation/Sections/Clearfix.js";
import Dropdown from "layouts/Documentation/Sections/Dropdown.js";
import FixedPlugin from "layouts/Documentation/Sections/FixedPlugin.js";
import Footer from "layouts/Documentation/Sections/Footer.js";
import Grid from "layouts/Documentation/Sections/Grid.js";
import Header from "layouts/Documentation/Sections/Header.js";
import Heading from "layouts/Documentation/Sections/Heading.js";
import InfoArea from "layouts/Documentation/Sections/InfoArea.js";
import Input from "layouts/Documentation/Sections/Input.js";
import Instruction from "layouts/Documentation/Sections/Instruction.js";
import LinearProgress from "layouts/Documentation/Sections/LinearProgress.js";
import Modal from "layouts/Documentation/Sections/Modal.js";
import NavPills from "layouts/Documentation/Sections/NavPills.js";
import Pagination from "layouts/Documentation/Sections/Pagination.js";
import Select from "layouts/Documentation/Sections/Select.js";
import Sidebar from "layouts/Documentation/Sections/Sidebar.js";
import Snackbar from "layouts/Documentation/Sections/Snackbar.js";
import Table from "layouts/Documentation/Sections/Table.js";
import Tabs from "layouts/Documentation/Sections/Tabs.js";
import Tasks from "layouts/Documentation/Sections/Tasks.js";
import Timeline from "layouts/Documentation/Sections/Timeline.js";
import Tooltips from "layouts/Documentation/Sections/Tooltips.js";
import Typography from "layouts/Documentation/Sections/Typography.js";
import Upload from "layouts/Documentation/Sections/Upload.js";
import Wizard from "layouts/Documentation/Sections/Wizard.js";

// Plugins
import Calendar from "layouts/Documentation/Sections/Calendar.js";
import Charts from "layouts/Documentation/Sections/Charts.js";
import DateTimePicker from "layouts/Documentation/Sections/DateTimePicker.js";
import GoogleMaps from "layouts/Documentation/Sections/GoogleMaps.js";
import PerfectScrollbar from "layouts/Documentation/Sections/PerfectScrollbar.js";
import ReactTables from "layouts/Documentation/Sections/ReactTables.js";
import Sliders from "layouts/Documentation/Sections/Sliders.js";
import SweetAlert from "layouts/Documentation/Sections/SweetAlert.js";
import Tags from "layouts/Documentation/Sections/Tags.js";
import VectorMaps from "layouts/Documentation/Sections/VectorMaps.js";

const docsRoutes = [
  {
    name: "Getting started",
    path: "/documentation/tutorial",
    routes: [
      {
        path: "/documentation/tutorial",
        name: "Tutorial",
        component: Tutorial
      },
      {
        path: "/documentation/routing-system",
        name: "RoutingSystem",
        component: RoutingSystem
      },
      {
        path: "/documentation/variables",
        name: "Variables",
        component: Variables
      },
      { path: "/documentation/styles", name: "Styles", component: Styles },
      { path: "/documentation/lint", name: "Lint", component: Lint },
      {
        path: "/documentation/build-tools",
        name: "Build Tools",
        component: BuildTools
      }
    ]
  },
  {
    name: "Components",
    path: "/documentation/accordion",
    routes: [
      {
        path: "/documentation/accordion",
        name: "Accordion",
        component: Accordion
      },
      { path: "/documentation/badge", name: "Badge", component: Badge },
      { path: "/documentation/buttons", name: "Buttons", component: Buttons },
      { path: "/documentation/cards", name: "Cards", component: Cards },
      {
        path: "/documentation/check-radio-switch",
        name: "CheckRadioSwitch",
        component: CheckboxRadioSwitch
      },
      {
        path: "/documentation/clearfix",
        name: "Clearfix",
        component: Clearfix
      },
      {
        path: "/documentation/dropdown",
        name: "Dropdown",
        component: Dropdown
      },
      {
        path: "/documentation/fixed-plugin",
        name: "FixedPlugin",
        component: FixedPlugin
      },
      { path: "/documentation/footer", name: "Footer", component: Footer },
      { path: "/documentation/grid", name: "Grid", component: Grid },
      { path: "/documentation/header", name: "Header", component: Header },
      { path: "/documentation/heading", name: "Heading", component: Heading },
      {
        path: "/documentation/info-area",
        name: "InfoArea",
        component: InfoArea
      },
      { path: "/documentation/input", name: "Inputs", component: Input },
      {
        path: "/documentation/instruction",
        name: "Instruction",
        component: Instruction
      },
      {
        path: "/documentation/linear-progress",
        name: "LinearProgress",
        component: LinearProgress
      },
      { path: "/documentation/modal", name: "Modal", component: Modal },
      {
        path: "/documentation/nav-pills",
        name: "NavPills",
        component: NavPills
      },
      {
        path: "/documentation/pagination",
        name: "Pagination",
        component: Pagination
      },
      { path: "/documentation/select", name: "Select", component: Select },
      { path: "/documentation/sidebar", name: "Sidebar", component: Sidebar },
      {
        path: "/documentation/snackbar",
        name: "Snackbar",
        component: Snackbar
      },
      { path: "/documentation/table", name: "Table", component: Table },
      { path: "/documentation/tabs", name: "Tabs", component: Tabs },
      { path: "/documentation/tasks", name: "Tasks", component: Tasks },
      {
        path: "/documentation/timeline",
        name: "Timeline",
        component: Timeline
      },
      {
        path: "/documentation/tooltips",
        name: "Tooltips",
        component: Tooltips
      },
      {
        path: "/documentation/typography",
        name: "Typography",
        component: Typography
      },
      { path: "/documentation/upload", name: "Upload", component: Upload },
      { path: "/documentation/wizard", name: "Wizard", component: Wizard }
    ]
  },
  {
    name: "Plugins",
    path: "/documentation/charts",
    routes: [
      {
        path: "/documentation/calendar",
        name: "Calendar",
        component: Calendar
      },
      { path: "/documentation/charts", name: "Charts", component: Charts },
      {
        path: "/documentation/date-time-picker",
        name: "DateTimePicker",
        component: DateTimePicker
      },
      {
        path: "/documentation/google-maps",
        name: "GoogleMaps",
        component: GoogleMaps
      },
      {
        path: "/documentation/perfect-scrollbar",
        name: "PerfectScrollbar",
        component: PerfectScrollbar
      },
      {
        path: "/documentation/react-table",
        name: "ReactTable",
        component: ReactTables
      },
      { path: "/documentation/sliders", name: "Sliders", component: Sliders },
      {
        path: "/documentation/sweet-alert",
        name: "SweetAlert",
        component: SweetAlert
      },
      { path: "/documentation/tags", name: "Tags", component: Tags },
      {
        path: "/documentation/vector-maps",
        name: "VectorMaps",
        component: VectorMaps
      }
    ]
  },
  { redirect: true, path: "/documentation", pathTo: "/documentation/tutorial" }
];

export default docsRoutes;
