import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import AdminService from "services/admin.service";
import "moment/locale/ru";
import Preloader from "../../components/Preloader/preloader.js";
import useSound from "use-sound";
import clickSound from "../../assets/sound/ui-click.wav";
import messageSound from "../../assets/sound/notification-up.wav";
import Snackbar from "../../components/Snackbar/Snackbar";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import classNames from "classnames";
import CustomInputPhone from "../../components/CustomInput/CustomInputPhone";
import {Group} from "@material-ui/icons";
const useStyles = makeStyles(styles);

const EditMasters = (props) => {
    const classes = useStyles();
    const {
        // eslint-disable-next-line react/prop-types
        params: { id },
    } = props.match;
    const [successSelect, setSuccess] = useState(false);
    const [errorSelect, setError] = useState(false);
    const labelClasses = classNames({
        [" " + classes.labelRootError]: errorSelect,
        [" " + classes.labelRootSuccess]: successSelect && !errorSelect,
    });
    const underlineClasses = classNames({
        [classes.underlineError]: errorSelect,
        [classes.underlineSuccess]: successSelect && !errorSelect,
        [classes.underline]: true,
        //[classes.whiteUnderline]: white,
    });


    const [dataLoading, setDataLoading] = useState(true);
    const [name, setName] = useState("");
    const [nameState, setNameState] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneState, setPhoneState] = useState("");
    const [category, setCategory] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const verifyLength = (value, length) => {
        return value.length >= length;
    };
    const verifyDigitsCount = (value, length) => {
        return value.replace(/[^0-9]/g, "").length === length;
    }
    const onChangeName = (e) => {
        if (verifyLength(e.target.value, 1)) {
            setNameState("success");
        } else {
            setNameState("error");
        }
        setName(e.target.value);
    };
    const onChangePhone = (e) => {
        if (verifyDigitsCount(e.target.value, 1)) {
            setPhoneState("default");
        } else if (verifyDigitsCount(e.target.value, 11)) {
            setPhoneState("success");
        } else {
            setPhoneState("error");
        }
        setPhone(e.target.value.replace(/[^0-9]/g, "").substring(1));

    };

    const onChangeCategory = (e) => {
        if (verifyLength(e.target.value, 1)) {
            setSuccess(true);
        } else {
            setError(true);
        }
        setCategory(e.target.value);
    };
    useEffect(() => {
        AdminService.getCategories()
            .then((response) => {
                const dataCat = response.data.data.map((prop) => {
                    const item = prop.attributes;
                    return {
                        id: prop.id,
                        title: item.title,
                    };
                });
                setCategoryData(dataCat);
            })
            .catch((error) => {
                alert(error);
            });


        AdminService.getMaster(id)
            .then((response) => {
                if (response.data.data.attributes) {
                    const master = response.data.data.attributes;

                    setName(master.name ? master.name : "");
                    setPhone(master.name ? master.phone.replace(/[^0-9]/g, "").substring(1):"");
                    setCategory(master.category_id ? JSON.parse(master.category_id) : "");
                    setDataLoading(false);
                }
            })
            .catch((error) => {
                alert(error);
            });


    }, [id]);
    const [playClick] = useSound(clickSound);
    const [playMessage] = useSound(messageSound);
    const [notificationMessage, setMessage] = useState("");
    const [notificationBg, setMessageBg] = useState("warning");
    const [tr, setTR] = useState(false);
    const showNotification = () => {
        setTR(true);
        setTimeout(function () {
            setTR(false);
        }, 6000);
    };
    const handleUpdateMaster = (e) => {
        e.preventDefault();
        if (name === "") {
            setNameState("error");
        }
        if (phone === "") {
            setPhoneState("error");
        }
        if (
            nameState !== "error" &&
            phoneState !== "error"
        ) {
            // eslint-disable-next-line
            var data = {
                type: "masters",
                id: id,
                attributes: {
                    name: name,
                    phone: phone,
                    category_id: JSON.stringify(category),
                },
            };
            AdminService.updateMaster(data, id)
                .then(() => {
                    setNameState("default");
                    setPhoneState("default");
                    playMessage();
                    setMessage("Услуга изменена!");
                    setMessageBg("success");
                    showNotification("tr");
                })
                .catch((error) => {
                    var message = error.toString();
                    playMessage();
                    setMessage(message);
                    setMessageBg("danger");
                    showNotification("tr");
                });
        }
    };


    const categoriesList = categoryData.map((item) => {
        return <MenuItem
            classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelectedMultiple
            }}
            value={item.id}
            key={item.id}
        >
            {item.title}
        </MenuItem>
    })


    return (
        <>
            {dataLoading ? (
                <Preloader />
            ) : (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                    <Group />
                                </CardIcon>
                                <h4 className={classes.cardIconTitle}>Сотрудник: {name}</h4>
                            </CardHeader>

                            <CardBody>
                                <form onSubmit={handleUpdateMaster}>
                                    <GridContainer>
                                        <GridItem lg={6}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={12} md={12} >
                                                    <CustomInput
                                                        labelText="Название"
                                                        id="name"
                                                        success={nameState === "success"}
                                                        error={nameState === "error"}
                                                        default={nameState === "default"}
                                                        formControlProps={{
                                                            fullWidth: true,
                                                        }}
                                                        inputProps={{
                                                            value: name,
                                                            onChange: onChangeName,
                                                        }}
                                                    />
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <CustomInputPhone
                                                        mask="+7(999)999-99-99"
                                                        labelText="Телефон"
                                                        id="phone"
                                                        formControlProps={{
                                                            fullWidth: true
                                                        }}
                                                        onChange={onChangePhone}
                                                        success={phoneState === "success"}
                                                        error={phoneState === "error"}
                                                        default={phoneState === "default"}
                                                        inputProps={{
                                                            value:phone
                                                        }}
                                                    />

                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={12} >
                                                    <FormControl fullWidth className={classes.formControl}>
                                                        <InputLabel
                                                            htmlFor="multiple-select"
                                                            className={classes.labelRoot + " " + labelClasses}
                                                            style={{ marginTop: "5px" }}
                                                        >
                                                            Категория
                                                        </InputLabel>
                                                        <Select
                                                            multiple
                                                            className={underlineClasses}
                                                            MenuProps={{
                                                                className: classes.selectMenu,
                                                            }}
                                                            classes={{
                                                                select: classes.select,
                                                            }}
                                                            value={category}
                                                            onChange={onChangeCategory}
                                                            inputProps={{
                                                                name: "multipleSelect",
                                                                id: "multiple-select",
                                                            }}
                                                        >
                                                            <MenuItem
                                                                disabled
                                                                classes={{
                                                                    root: classes.selectMenuItem,
                                                                }}
                                                            >
                                                                Категория
                                                            </MenuItem>
                                                            {categoriesList}
                                                        </Select>
                                                    </FormControl>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>
                                    </GridContainer>
                                    <Button
                                        color="rose"
                                        className={classes.updateProfileButton}
                                        onClick={playClick}
                                        type="submit"
                                    >
                                        Сохранить
                                    </Button>
                                    <Clearfix />
                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <Snackbar
                        place="tr"
                        color={notificationBg}
                        message={notificationMessage}
                        open={tr}
                        closeNotification={() => setTR(false)}
                        close
                    />
                </GridContainer>
            )}
        </>
    );
};

export default EditMasters;
