import React from "react";
// react component plugin for creating beatiful tags on an input
import TagsInputs from "react-tagsinput";

import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";

const exampleTagsInput = `import React from 'react';
// react component plugin for creating beatiful tags on an input
import TagsInput from "react-tagsinput";

export default function TagsInput(){
  const [tags, setTags] = React.useState(["pizza", "pasta", "parmesan"]);

  const handleTags = regularTags => {
    setTags(regularTags);
  };
  return (
    <TagsInput
      value={tags}
      onChange={handleTags}
      tagProps={{ className: "react-tagsinput-tag info" }}
    />
  );
}

export default Tags;`;

export default function TagsInput() {
  const [tags, setTags] = React.useState(["pizza", "pasta", "parmesan"]);

  const handleTags = regularTags => {
    setTags(regularTags);
  };

  return (
    <div>
      <h1>React Tagsinput v3.19.0</h1>
      <p>
        For this we've used{" "}
        <a
          href="https://github.com/olahol/react-tagsinput?ref=creativetim"
          target="_blank" rel="noopener noreferrer"
        >
          react-tagsinput
        </a>{" "}
        and restyled it to match out theme.
      </p>
      <p>
        You can pass the following <code>classNames</code> to get the desire
        color, <code>react-tagsinput-tag $color</code>, where{" "}
        <code>$color</code> can be one of <code>primary</code>,{" "}
        <code>info</code>, <code>success</code>, <code>warning</code> or{" "}
        <code>danger</code>.
      </p>
      <p>
        Please refer to{" "}
        <a
          href="https://github.com/olahol/react-tagsinput?ref=creativetim"
          target="_blank" rel="noopener noreferrer"
        >
          react-tagsinput documentation
        </a>{" "}
        for more information.
      </p>
      <h2>Styles</h2>
      <p>
        You will find the styles for this component in
        <br />{" "}
        <code>
          src/assets/scss/material-kit-pro-react/plugins/_plugin-react-tagsinput.scss
        </code>
        .
      </p>
      <h2>Example</h2>
      <div className="border-example-8">
        <TagsInputs
          value={tags}
          onChange={handleTags}
          tagProps={{ className: "react-tagsinput-tag info" }}
        />
      </div>
      <SyntaxHighlighter language="jsx" style={prism}>
        {exampleTagsInput}
      </SyntaxHighlighter>
    </div>
  );
}
