import React, {useState, useEffect, useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";
import PermIdentity from "@material-ui/icons/PermIdentity";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import moment from "moment";
import AdminService from "services/admin.service";
import "moment/locale/ru";
import CustomInputPhone from "../../components/CustomInput/CustomInputPhone";
import Preloader from "../../components/Preloader/preloader.js"
import CustomInputDateTimeEdit from "../../components/CustomInput/CustomInputDateTimeEdit";
import useSound from "use-sound";
import clickSound from "../../assets/sound/ui-click.wav";
import messageSound from "../../assets/sound/notification-up.wav";
import Snackbar from "../../components/Snackbar/Snackbar";
import CardAvatar from "../../components/Card/CardAvatar";
import defaultImage from "../../assets/img/default-avatar.png";
const useStyles = makeStyles(styles);

const EditClients = (props) => {
    const classes = useStyles();
    const {
        params: {clientId},
    } = props.match;

    const [dataLoading, setDataLoading] = useState(true);
    const [name, setName] = useState("");
    const [nameState, setNameState] = useState("");
    const [email, setEmail] = useState("");
    const [emailState, setEmailState] = useState("");
    const [phone, setPhone] = useState("");
    const [phoneState, setPhoneState] = useState("");
    const [dob, setDob] = useState({});
    const [dobState, setDobState] = useState("");
    const [instagram, setInstagram] = useState("");
    const [instagramState, setInstagramState] = useState("");
    const [comment, setComment] = useState("");
    const [commentState, setCommentState] = useState("");


    const verifyLength = (value, length) => {
        if (value.length >= length) {
            return true;
        }
        return false;
    };
    const verifyDigitsCount = (value, length) => {
        if (value.replace(/[^0-9]/g, "").length === length) {
            return true;
        }
        return false;
    }
    const onChangeName = (e) => {
        if (verifyLength(e.target.value, 1)) {
            setNameState("success");
        } else {
            setNameState("error");
        }
        setName(e.target.value);

    };
    const onChangeEmail = (e) => {
        if (verifyLength(e.target.value, 1)) {
            setEmailState("success");
        }
        // else {
        //     setEmailState("error");
        // }
        setEmail(e.target.value);

    };
    const onChangePhone = (e) => {
        if (verifyDigitsCount(e.target.value, 1)) {
            setPhoneState("default");
        }
        else if (verifyDigitsCount(e.target.value, 11)) {
            setPhoneState("success");
        }
        else {
            setPhoneState("error");
        }
        setPhone(e.target.value.replace(/[^0-9]/g, "").substring(1));

    };
    const onChangeDob = (e) => {
        if (e === null || e === "") {
            setDobState("error");
        } else {
            if (typeof e === 'object') {
                setDobState("success");
            } else {
                setDobState("error");
            }
        }
        setDob(moment(e).format("YYYY-MM-DD"));

    };
    const onChangeSocials = (e) => {
        if (verifyLength(e.target.value, 1)) {
            setInstagramState("success");

        } else {
            setInstagramState("error");
        }
        setInstagram(e.target.value);

    };
    const onChangeComment = (e) => {
        if (verifyLength(e.target.value, 1)) {
            setCommentState("success");
        }
        // else {
        //     setCommentState("error");
        // }
        setComment(e.target.value);
    };
    useEffect(() => {
        AdminService.getClient(clientId)
            .then((response) => {
                if (response.data.data.attributes){
                    const client = response.data.data.attributes;
                    setName(client.name ? client.name:"" );
                    setPhone(client.phone.replace(/[^0-9]/g, "").substring(1));
                    setEmail(client.email ? client.email:"");
                    setDob(client.dob ? moment(client.dob): null);
                    setInstagram(client.socials ? JSON.parse(client.socials).instagram:"");
                    setComment(client.comment ? client.comment: "");
                    setImagePreviewUrl(client.ava ? process.env.REACT_APP_CLIENT_AVA_URL+clientId+'/'+client.ava : defaultImage );
                    setDataLoading(false);
                }
            })
            .catch((error) => {
                alert(error);
            })
    },[clientId]);
    const [playClick] = useSound(clickSound);
    const [playMessage] = useSound(messageSound);
    const [notificationMessage, setMessage] = useState("");
    const [notificationBg, setMessageBg] = useState("warning");
    const [tr, setTR] = useState(false);
    const showNotification = () => {
        setTR(true);
        setTimeout(function() {
            setTR(false);
        }, 6000);
    }
    const handleUpdateClientProfile = (e) => {
        e.preventDefault();
        if (name === "") {
            setNameState("error");
        }
        if (phone === "") {
            setPhoneState("error");
        }
        if (dob === "") {
            setDobState("error");
        }

        if(nameState !== "error" && dobState !== "error" && phoneState !== "error"){
            // eslint-disable-next-line
            let insta = '{"instagram":'+ '"'+ instagram +'"}';
            var data = {

                "type": "clients",
                "id": clientId,
                "attributes": {
                    "name": name,
                    "email": email,
                    "dob":moment(dob).format("YYYY-MM-DD"),
                    "phone":phone,
                    "comment":comment,
                    "socials": insta
                }

            };

            AdminService.updateClientProfile(data,clientId)
                .then(
                    (response)=>{

                        setNameState('default');
                        setEmailState('default');
                        setPhoneState('default');
                        setDobState('default');
                        setInstagramState('default');
                        setCommentState('default');
                        playMessage();
                        setMessage("Профиль изменен!");
                        setMessageBg('success');
                        showNotification("tr");
                    }
                )
                .catch((error) => {
                        var message = error.toString();
                        playMessage();
                        setMessage(message);
                        setMessageBg('danger');
                        showNotification("tr");

                    }
                );
        }
    }
    //обновления авы
    const [file, setFile] = useState(null);
    const formRef = useRef(null)
    const [imagePreviewUrl, setImagePreviewUrl] = useState(defaultImage);
    const handleImageChange = e => {
        e.preventDefault();
        let reader = new FileReader();
        let newFile = e.target.files[0];

        reader.onloadend = () => {
            setFile(newFile);
            setImagePreviewUrl(reader.result);
            //отправка формы
            formRef.current.dispatchEvent(new Event("submit"));
        };
        if (newFile) {

            reader.readAsDataURL(newFile);

        }
    };
    const handleUpdateAva = (e) => {
        e.preventDefault();
        // console.log(file);
        AdminService.updateClientAva(clientId, file)
            .then(
                (response)=>{

                    playMessage();
                    setMessage(response.data.success);
                    setMessageBg('success');
                    showNotification("tr");
                }
            )
            .catch((error) => {
                    playMessage();
                    setMessage(error.toString());
                    setMessageBg('danger');
                    showNotification("tr");
                }
            );

    };
    return (
        <>
        {dataLoading ? <Preloader/> :
        <GridContainer>

            <GridItem xs={12} sm={12} md={8}>
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <PermIdentity/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>
                            Профиль клиента: {name}
                        </h4>
                    </CardHeader>

                  <CardBody>
                        <form onSubmit={handleUpdateClientProfile}>
                            <GridContainer>
                                <GridItem xs={12} sm={12}  md={12} lg={7}>
                                    <CustomInput
                                        labelText="Имя"
                                        id="client-name"
                                        success={nameState === "success"}
                                        error={nameState === "error"}
                                        default={nameState === "default"}
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        inputProps={{
                                            value: name,
                                            onChange: onChangeName,
                                        }}

                                    />
                                </GridItem>

                                <GridItem xs={12} sm={12}  md={12} lg={5}>
                                    <CustomInputPhone
                                        mask="+7(999)999-99-99"
                                        labelText="Телефон"
                                        id="phone"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        onChange={onChangePhone}
                                        beforeMaskedStateChange={() => {
                                            return phone
                                        }}
                                        success={phoneState === "success"}
                                        error={phoneState === "error"}
                                        default={phoneState === "default"}
                                        inputProps={{
                                            value: phone,

                                        }}
                                    />
                                </GridItem>
                               </GridContainer>
                            <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={3}>
                            <CustomInputDateTimeEdit
                            labelText="Дата рождения"
                            id="client-dob"
                            formControlProps={{
                            fullWidth: true,
                            style:{
                                height:"initial"
                            }
                            }}

                            success={dobState === "success"}
                            error={dobState === "error"}
                            default={dobState === "default"}
                            inputProps={{
                            autoComplete: "off",
                            onChange: onChangeDob,
                            value: dob

                        }}
                            />



                            </GridItem>


                            <GridItem xs={12} sm={12}  md={12} lg={4}>
                            <CustomInput
                            labelText="Instagram"
                            id="client-instagram"
                            success={instagramState === "success"}
                            error={instagramState === "error"}
                            default={instagramState === "default"}
                            formControlProps={{
                            fullWidth: true
                        }}
                            inputProps={{

                            onChange: onChangeSocials,
                            value: instagram,
                        }}
                            />
                            </GridItem>
                            <GridItem xs={12} sm={12}  md={12} lg={5}>
                            <CustomInput
                            labelText="Email"
                            id="client-email"
                            formControlProps={{
                            fullWidth: true
                        }}
                            success={emailState === "success"}
                            error={emailState === "error"}
                            default={emailState === "default"}
                            inputProps={{
                            value: email,
                            onChange: onChangeEmail
                        }}
                            />
                            </GridItem>
                            </GridContainer>
                            <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>

                            <CustomInput
                            labelText="Комментарий"
                            id="client-comment"
                            formControlProps={{
                            fullWidth: true
                              }}
                            success={commentState === "success"}
                            error={commentState === "error"}
                            default={commentState === "default"}
                            inputProps={{
                            multiline: true,
                            rows: 5,
                            value:comment,
                            onChange:onChangeComment
                        }}
                            />
                            </GridItem>
                            </GridContainer>
                            <Button
                                color="rose"
                                className={classes.updateProfileButton}
                                onClick = {playClick}
                                type="submit"
                            >
                            Сохранить
                            </Button>
                            <Clearfix/>
                        </form>
                    </CardBody>

                </Card>
            </GridItem>

            <GridItem xs={12} sm={12} md={4}>
                <Card profile>
                    <CardAvatar >
                        <form onSubmit={handleUpdateAva} ref={formRef}>
                            <div className="picture-container">
                                <div className="picture">
                                    <img src={imagePreviewUrl} className="picture-src" alt="user"/>
                                    <input type="file" onChange={ handleImageChange}/>
                                </div>
                            </div>
                        </form>
                    </CardAvatar>

                    <CardBody profile>
                        <h4 className={classes.cardTitle}>{name}</h4>
                        <p className={classes.description}>
                            {comment}
                        </p>
                        {instagram ?
                            <Button
                                round
                                justIcon
                                color="rose"
                                onClick={(e) => {
                                    e.preventDefault();
                                    window.open('https://www.instagram.com/' + instagram,'_blank');
                                }}
                            >
                                <i className={"fab fa-instagram"} />
                            </Button>
                            : ""
                        }

                        <Button
                            round
                            justIcon
                            color="success"
                            onClick={(e) => {
                                e.preventDefault();
                                window.open('https://api.whatsapp.com/send?phone=+7'+phone+'&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!' ,'_blank');
                            }}
                        >
                            <i className={"fab fa-whatsapp"} />
                        </Button>
                    </CardBody>
                </Card>
            </GridItem>

            <Snackbar
                place="tr"
                color={notificationBg}
                message = {notificationMessage}
                open={tr}
                closeNotification={() => setTR(false)}
                close
            />

        </GridContainer>
        }
        </>

    );
}

export default EditClients;
