import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js"; 
import CardBody from "components/Card/CardBody.js";
import Avatar from '@material-ui/core/Avatar';
const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
  });
  const ClientModal = (props) => {
  const classes = useStyles();
  const {  open,clientId, clientName,clientPhone,clientSocials, onClose,avaColor,clientAva} = props;
  return(
      <Dialog
        classes={{
          root: classes.center + " " + classes.modalRoot,
          paper: classes.modal
        }}       
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="notice-modal-slide-title"
        aria-describedby="notice-modal-slide-description"      
        fullWidth
      >
        <DialogTitle
          id="notice-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={onClose}
          >
            <Close className={classes.modalClose} />
          </Button>
    
        </DialogTitle>
        <DialogContent
          id="notice-modal-slide-description"
          className={classes.modalBody}
        >
        <Avatar
              style={{ 
                fontSize: '40px',
                height: '130px',
                width: '130px' ,
                margin:'-50px auto 0',
               // boxShadow: '0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
                backgroundColor: avaColor
              }} 
              alt={clientName}
              src={clientAva ? process.env.REACT_APP_CLIENT_AVA_URL+clientId+'/'+clientAva : ""}
            />
            
            <CardBody profile>
              <h6 className={classes.cardCategory}>ID#{clientId}</h6>
              <h4 className={classes.cardTitle}>{clientName}</h4>
              <p>{clientPhone}</p>
            <div style={{marginTop: "20px"}}>
            
            {clientSocials != null && clientSocials.instagram ? 
            <Button 
            round
            justIcon 
            color="rose"
            onClick={(e) => {
              e.preventDefault();              
              window.open('https://www.instagram.com/' + clientSocials.instagram,'_blank');
              }}
            >
                <i className={"fab fa-instagram"} />
            </Button>
             : ""
            }
            
            <Button 
            round
            justIcon 
            color="success"
            onClick={(e) => {
              e.preventDefault();              
              window.open('https://api.whatsapp.com/send?phone='+clientPhone+'&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!' ,'_blank');
              }}
            >
                <i className={"fab fa-whatsapp"} />
            </Button>

            </div>
            </CardBody>
         
          
         
         
          
        </DialogContent>
        
      </Dialog>  
    )
 
  }
  
  export default ClientModal;