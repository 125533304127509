export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
  
    if (user && user.accessToken) {
           return { 
             "Authorization" : "Bearer " + user.accessToken,
             "Content-Type" : "application/vnd.api+json", 
             "Accept" : "application/vnd.api+json"

            };
    } else {
     
      return {};
    }
  }