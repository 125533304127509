/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";

const codeProps = `Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool
};`;
const codePropsPages = `Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
};`;

function Header({ ...props }) {
  //  const { } = props;
  return (
    <div>
      <h1>AdminNavbar</h1>
      <p>
        This is the top navigation of our demo app for the <code>Admin</code>{" "}
        layout component.
      </p>
      <p>
        It was made using{" "}
        {
          " "
          // eslint-disable-next-line
        }
        <a href="https://material-ui-next.com/demos/app-bar/" target="_blank">
          Material-UI's AppBar
        </a>
        , but with some custom styling.
      </p>
      <p>
        You will find this component in{" "}
        <code>src/components/Navbars/AdminNavbar.js</code>. In this component
        there is just the structure of the navbar. The links can be found in{" "}
        <code>src/components/Navbars/NavbarsLinks.js</code>. We've done this
        because on responsive we want both the links from <code>Navbar</code>{" "}
        and those from <code>Sidebar</code> components to be displayed.
      </p>
      <p>
        You can choose between 5 colors by using the <code>color</code>{" "}
        property.
      </p>
      <p>If this property is not set, then the Appbar will be transparent.</p>
      <p>
        <b>
          The navbar brand is made from the route's name. Please read about this{" "}
          <Link to="/documentation/routing-system">here</Link>.
        </b>
      </p>
      <h2>Styles</h2>
      <p>
        You will find the styles for this component in
        <br />{" "}
        <code>
          src/assets/jss/material-dashboard-pro-react/components/headerStyle.js
        </code>
        .
      </p>
      <h2>Props</h2>
      <SyntaxHighlighter language="jsx" style={prism}>
        {codeProps}
      </SyntaxHighlighter>
      <h1>AuthNavbar</h1>
      <p>
        This is the top navigation of our demo app for the <code>Auth</code>{" "}
        layout component.
      </p>
      <p>
        You will find this component in{" "}
        <code>src/components/Navbars/AuthNavbar.js</code>
      </p>
      <p>
        It's made like the <code>Header</code> component but the difference is
        that the links in this component are made dynamically using{" "}
        <code>src/routes/pages.js</code>.
      </p>
      <h2>Styles</h2>
      <p>
        You will find the styles for this component in
        <br />{" "}
        <code>
          src/assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle.js
        </code>
        .
      </p>
      <h2>Props</h2>
      <SyntaxHighlighter language="jsx" style={prism}>
        {codePropsPages}
      </SyntaxHighlighter>
    </div>
  );
}

export default Header;
