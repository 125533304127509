import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { useHistory } from "react-router-dom";
import AdminService from "services/admin.service";
import ReactTable from "components/ReactTable/ReactTable.js";
import AddCategoryModal from "../../components/Categories/AddCategoryModal";
import useSound from "use-sound";
import clickSound from "../../assets/sound/ui-click.wav";
import messageSound from "../../assets/sound/notification-up.wav";
import SweetAlert from "react-bootstrap-sweetalert";
import {Category} from "@material-ui/icons";

const useStyles = makeStyles(styles);

// eslint-disable-next-line no-unused-vars
const Categories = () => {
  const classes = useStyles();
  const history = useHistory();
  const [openAcm, setAddCategoryModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const handleCloseModal = () => {
    setAddCategoryModal(false);
  };
  const [playClick] = useSound(clickSound);
  const [playMessage] = useSound(messageSound);
  const [alert, setAlert] = useState(null);
  const hideAlert = () => {
    setAlert(null);
  };
  const [services, setServices] = useState([]);

  useEffect(() => {
    AdminService.getCategories()
      .then((response) => {
        if (response.data.data.length === 0) {
          setTableLoading(false);
        }

        const data = response.data.data.map((prop) => {
          setTableLoading(false);

          const item = prop.attributes;

          return {
            id: prop.id,
            title: item.title,
            color: (
              <div>
                <span style={{ width: "70px", display: "inline-block" }}>
                  {item.color}
                </span>
                <span
                  style={{
                    backgroundColor: "#" + item.color,
                    width: "50px",
                    height: "10px",
                    display: "inline-block",
                    marginLeft: "10px",
                    borderRadius: "4px",
                  }}
                />
              </div>
            ),
            actions: (
              <div style={{ textAlign: "center" }}>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    playClick();
                    history.push("/admin/categories/edit/" + prop.id);
                  }}
                  color="success"
                  className={classes.actionButton}
                >
                  <Edit />
                </Button>{" "}
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    playClick();
                    setAlert(
                      <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Вы уверены?"
                        onConfirm={() =>
                          AdminService.deleteCategory(prop.id)
                            .then((response) => {
                              if (response.status === 204) {
                                playMessage();
                                setAlert(
                                  <SweetAlert
                                    success
                                    style={{
                                      display: "block",
                                      marginTop: "-100px",
                                    }}
                                    title="Удалили успешно!"
                                    onConfirm={() => hideAlert()}
                                    onCancel={() => hideAlert()}
                                    confirmBtnCssClass={classes.btnSuccess}
                                  ></SweetAlert>
                                );
                                var newData = data;
                                newData.find((o, i) => {
                                  if (o.id === prop.id) {
                                    newData.splice(i, 1);
                                    return true;
                                  }
                                  return false;
                                });
                                setServices([...newData]);
                              }
                            })
                            .catch((error) => {
                              alert(error);
                            })
                        }
                        onCancel={() => hideAlert()}
                        confirmBtnCssClass={classes.btnSuccess}
                        cancelBtnCssClass={classes.btnRose}
                        confirmBtnText="Да, давай удалим!"
                        cancelBtnText="Нет"
                        showCancel
                      >
                        Будет удалена категория {item.title}
                      </SweetAlert>
                    );
                  }}
                  color="danger"
                  className={classes.actionButton}
                >
                  <Close />
                </Button>{" "}
              </div>
            ),
          };
        });

        setServices(data);
      })
      .catch((error) => {
        setTableLoading(false);
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Category />
            </CardIcon>
            <div className={classes.addButtonContainer}>
              <Button
                onClick={() => {
                  setAddCategoryModal(true);
                }}
                color="primary"
              >
                Добавить категорию
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <hr />

            {
              <ReactTable
                loading={tableLoading}
                columns={[
                  {
                    Header: "Название",
                    accessor: "title",
                  },
                  {
                    Header: "Цвет(в расписании)",
                    accessor: "color",
                  },
                  {
                    // eslint-disable-next-line react/display-name
                    Header: () => (
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        Действия
                      </div>
                    ),
                    accessor: "actions",
                  },
                ]}
                data={services}
              />
            }

            <AddCategoryModal open={openAcm} onClose={handleCloseModal} />
          </CardBody>
        </Card>
      </GridItem>
      {alert}
    </GridContainer>
  );
};

export default Categories;
