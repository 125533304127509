import buttonGroupStyle from "assets/jss/material-dashboard-pro-react/buttonGroupStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.js";

import {
  cardTitle,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";

const extendedTablesStyle = {
  ...customCheckboxRadioSwitch,
  ...buttonGroupStyle,
  ...buttonsStyle,
  right: {
    textAlign: "right"
  },
  center: {
    textAlign: "center"
  },
  description: {
    maxWidth: "150px"
  },
  actionButton: {
    margin: "0 0 0 5px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px"
    }
  },
  icon: {
    verticalAlign: "middle",
    width: "17px",
    height: "17px",
    top: "-1px",
    position: "relative"
  },
  imgContainer: {
    width: "120px",
    maxHeight: "160px",
    overflow: "hidden",
    display: "block"
  },
  img: {
    width: "100%",
    height: "auto",
    verticalAlign: "middle",
    border: "0"
  },
  tdName: {
    minWidth: "200px",
    fontWeight: "400",
    fontSize: "1.5em"
  },
  tdNameAnchor: {
    color: grayColor[2]
  },
  tdNameSmall: {
    color: grayColor[0],
    fontSize: "0.75em",
    fontWeight: "300"
  },
  tdNumber: {
    textAlign: "right",
    minWidth: "145px",
    fontWeight: "300",
    fontSize: "1.3em !important"
  },
  tdNumberSmall: {
    marginRight: "3px"
  },
  tdNumberAndButtonGroup: {
    lineHeight: "1 !important"
  },
  positionAbsolute: {
    position: "absolute",
    right: "0",
    top: "0"
  },
  customFont: {
    fontSize: "16px !important"
  },
  actionButtonRound: {
    width: "auto",
    height: "auto",
    minWidth: "auto"
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  btnSuccess: {
    boxShadow: "none !important",
    backgroundColor: "#4caf50",
    color: "#FFF !important",
    border: "none",
    cursor: "pointer",
    padding: "12px 30px",
    position: "relative",
    fontSize: "12px",
    minWidth: "auto",
    minHeight: "auto",
    textAlign: "center",
    fontWeight: "400",
    lineHeight: "1.42857143",
    whiteSpace: "nowrap",
    willChange: "box-shadow, transform",
    touchAction: "manipulation",
    borderRadius: "3px",
    letterSpacing: "0",
    textTransform: "uppercase",
    verticalAlign: "middle",
    marginRight:"none !important",
    borderColor:"transparent !important",

  },
  btnRose: {
    boxShadow: "none !important",
    backgroundColor: "#d81b60",
    color: "#FFF !important",
    border: "none",
    cursor: "pointer",
    padding: "12px 30px",
    position: "relative",
    fontSize: "12px",
    minWidth: "auto",
    minHeight: "auto",
    textAlign: "center",
    fontWeight: "400",
    lineHeight: "1.42857143",
    whiteSpace: "nowrap",
    willChange: "box-shadow, transform",
    touchAction: "manipulation",
    borderRadius: "3px",
    letterSpacing: "0",
    textTransform: "uppercase",
    verticalAlign: "middle",
    marginRight:"none !important",
    borderColor:"transparent !important",
  },
};

export default extendedTablesStyle;
