/*eslint-disable*/
import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui icons
import Check from "@material-ui/icons/Check";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import Accordion from "components/Accordion/Accordion.js";

import Checkboxes from "./CheckboxRadioSwitch/Checkboxes.js";
import Radios from "./CheckboxRadioSwitch/Radios.js";
import Switches from "./CheckboxRadioSwitch/Switches.js";

import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const codeImport = `import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";`;
const codeExampleCheckboxes = `import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
// core components
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const useStyles = makeStyles(styles);

export default function CheckboxExample() {
  const [checked, setChecked] = React.useState([24, 22]);
  const handleToggle = value => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const classes = useStyles();
  return (
    <div>
      <div
        className={
          classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              onClick={() => handleToggle(21)}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{ label: classes.label }}
          label="Unchecked"
        />
      </div>
      <div
        className={
          classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          control={
            <Checkbox
              tabIndex={-1}
              onClick={() => handleToggle(22)}
              checked={checked.indexOf(22) !== -1 ? true : false}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{ label: classes.label }}
          label="Checked"
        />
      </div>
      <div
        className={
          classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          disabled
          control={
            <Checkbox
              tabIndex={-1}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{
            label: classes.label,
            disabled: classes.disabledCheckboxAndRadio
          }}
          label="Disabled Unchecked"
        />
      </div>
      <div
        className={
          classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          disabled
          control={
            <Checkbox
              tabIndex={-1}
              checked={checked.indexOf(24) !== -1 ? true : false}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot
              }}
            />
          }
          classes={{
            label: classes.label,
            disabled: classes.disabledCheckboxAndRadio
          }}
          label="Disabled Checked"
        />
      </div>
    </div>
  );
}`;
const codeExampleRadios = `import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// core components
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const useStyles = makeStyles(styles);

export default function RadioExample() {
  const [selectedEnabled, setSelectedEnabled] = React.useState("b");
  const classes = useStyles();
  return (
    <div>
      <div
        className={
          classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          control={
            <Radio
              checked={selectedEnabled === "a"}
              onChange={() => setSelectedEnabled("a")}
              value="a"
              name="radio button enabled"
              aria-label="A"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot
              }}
            />
          }
          classes={{
            label: classes.label
          }}
          label="First Radio"
        />
      </div>
      <div
        className={
          classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          control={
            <Radio
              checked={selectedEnabled === "b"}
              onChange={() => setSelectedEnabled("b")}
              value="b"
              name="radio button enabled"
              aria-label="B"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
                root: classes.radioRoot
              }}
            />
          }
          classes={{
            label: classes.label
          }}
          label="Second Radio"
        />
      </div>
      <div
        className={
          classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          disabled
          control={
            <Radio
              checked={false}
              value="a"
              name="radio button disabled"
              aria-label="B"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
                disabled: classes.disabledCheckboxAndRadio,
                root: classes.radioRoot
              }}
            />
          }
          classes={{
            label: classes.label
          }}
          label="Disabled Unchecked Radio"
        />
      </div>
      <div
        className={
          classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
        }
      >
        <FormControlLabel
          disabled
          control={
            <Radio
              checked={true}
              value="b"
              name="radio button disabled"
              aria-label="B"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
                disabled: classes.disabledCheckboxAndRadio,
                root: classes.radioRoot
              }}
            />
          }
          classes={{ label: classes.label }}
          label="Disabled Checked Radio"
        />
      </div>
    </div>
  );
}`;
const codeExampleSwitches = `import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// core components
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const useStyles = makeStyles(styles);

export default function SwitchExample() {
  const [checkedA, setCheckedA] = React.useState(true);
  const [checkedB, setCheckedB] = React.useState(false);
  const classes = useStyles();
  return (
    <div>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={checkedA}
              onChange={event => setCheckedA(event.target.checked)}
              value="checkedA"
              classes={{
                switchBase: classes.switchBase,
                checked: classes.switchChecked,
                thumb: classes.switchIcon,
                track: classes.switchBar
              }}
            />
          }
          classes={{
            label: classes.label
          }}
          label="Toggle is on"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={checkedB}
              onChange={event => setCheckedB(event.target.checked)}
              value="checkedB"
              classes={{
                switchBase: classes.switchBase,
                checked: classes.switchChecked,
                thumb: classes.switchIcon,
                track: classes.switchBar
              }}
            />
          }
          classes={{
            label: classes.label
          }}
          label="Toggle is off"
        />
      </div>
    </div>
  );
}`;

export default function CheckboxRadioSwitch() {
  return (
    <div>
      <h1>Checkboxes, Radios and Switches</h1>
      <p>
        We haven't extended{" "}
        {
          " "
          // eslint-disable-next-line
        }
        <a
          href="https://material-ui-next.com/demos/selection-controls/?ref=creativetim"
          target="_blank"
        >
          these components from Material-UI
        </a>
        . We've just added our style over their components.
      </p>
      <p>
        To use our styles over any of these three components you need the
        following import:
      </p>
      <SyntaxHighlighter language="jsx" style={prism}>
        {codeImport}
      </SyntaxHighlighter>
      <p>Let's take a look at each one of them, and how to use our styles.</p>
      <h2>Checkboxes</h2>
      <div className="border-example">
        <Checkboxes />
        <Accordion
          collapses={[
            {
              title: "View Code",
              content: (
                <SyntaxHighlighter language="jsx" style={prism}>
                  {codeExampleCheckboxes}
                </SyntaxHighlighter>
              )
            }
          ]}
        />
      </div>
      <h2>Radios</h2>
      <div className="border-example">
        <Radios />
        <Accordion
          collapses={[
            {
              title: "View Code",
              content: (
                <SyntaxHighlighter language="jsx" style={prism}>
                  {codeExampleRadios}
                </SyntaxHighlighter>
              )
            }
          ]}
        />
      </div>
      <h2>Switches</h2>
      <div className="border-example">
        <Switches />
        <Accordion
          collapses={[
            {
              title: "View Code",
              content: (
                <SyntaxHighlighter language="jsx" style={prism}>
                  {codeExampleSwitches}
                </SyntaxHighlighter>
              )
            }
          ]}
        />
      </div>
      <h2>Props</h2>
      <p>
        For more info you can check out these links which will redirect you to
        the official Material-ui documentation:{" "}
        <a
          href="https://material-ui.com/api/checkbox/?ref=creativetim"
          target="_blank"
        >
          Checkbox
        </a>
        ,{" "}
        <a
          href="https://material-ui.com/api/radio/?ref=creativetim"
          target="_blank"
        >
          Radio
        </a>
        ,{" "}
        <a
          href="https://material-ui.com/api/switch/?ref=creativetim"
          target="_blank"
        >
          Switch
        </a>
        .
      </p>
    </div>
  );
}
