import React,{useState} from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import AddAlert from "@material-ui/icons/AddAlert";
//import Email from "@material-ui/icons/Email";
// import LockOutline from "@material-ui/icons/LockOutline";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

//notifications
import Snackbar from "components/Snackbar/Snackbar.js";

//sound
import useSound from 'use-sound';
import clickSound from "assets/sound/ui-click.wav";
import messageSound from "assets/sound/notification-up.wav"

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import AuthService from "services/auth.service";

//import {useHistory} from "react-router-dom";


const useStyles = makeStyles(styles);


const LoginPage = (props) => {
const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  },[]);
const classes = useStyles();
//const history = useHistory();
//var errorMessage = '';

const [errorMessage, setErrorMessage] = React.useState("");

const [tl, setTL] = React.useState(false);
const [tc, setTC] = React.useState(false);
const [tr, setTR] = React.useState(false);
const [bl, setBL] = React.useState(false);
const [bc, setBC] = React.useState(false);
const [br, setBR] = React.useState(false);
const showNotification = (place) => {
  switch (place) {
    case "tl":
      if (!tl) {
        setTL(true);
        setTimeout(function() {
          setTL(false);
        }, 6000);
      }
      break;
    case "tc":
      if (!tc) {
        setTC(true);
        setTimeout(function() {
          setTC(false);
        }, 10000);
      }
      break;
    case "tr":
      if (!tr) {
        setTR(true);
        setTimeout(function() {
          setTR(false);
        }, 6000);
      }
      break;
    case "bl":
      if (!bl) {
        setBL(true);
        setTimeout(function() {
          setBL(false);
        }, 6000);
      }
      break;
    case "bc":
      if (!bc) {
        setBC(true);
        setTimeout(function() {
          setBC(false);
        }, 6000);
      }
      break;
    case "br":
      if (!br) {
        setBR(true);
        setTimeout(function() {
          setBR(false);
        }, 6000);
      }
      break;
    default:
      break;
  }
};


const [username, setUsername] = useState("");
const [usernameState, setUsernameState] = useState("");
const [usernameVerifyEmailState, setUsernameVerifyEmailState] = useState("");

const [password, setPassword] = useState("");
const [passwordState, setPasswordState] = useState("");


 // function that returns true if value is email, false otherwise
 const verifyEmail = value => {
  var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (emailRex.test(value)) {
    return true;
  }
  return false;
};
// function that verifies if a string has a given length or not
const verifyLength = (value, length) => {
  if (value.length >= length) {
    return true;
  }
  return false;
};


const onChangeUsername = (e) => {
  if (verifyLength(e.target.value, 1)) {
    setUsernameState("success");
    
  } else {
    setUsernameState("error");
   
  }

  if (verifyEmail(e.target.value)) {
    setUsernameVerifyEmailState(true);
    setUsernameState("success");
    
  } 
  else {
    setUsernameVerifyEmailState(false);
    setUsernameState("error");
    
  } 
  setUsername(e.target.value);
};

const onChangePassword = (e) => {
  if (verifyLength(e.target.value, 1)) {
    setPasswordState("success");
  } else {
    setPasswordState("error");
  }
  setPassword(e.target.value);
  
};
const handleLogin = (e) => {
  e.preventDefault();

  if (usernameState === "" && passwordState === "") {
    setUsernameState("error");
    setPasswordState("error");    
  }
  if (usernameState === "") {
    setUsernameState("error");
  }
  if (passwordState === "") {
    setPasswordState("error");
  }
  
  if(usernameState === "success" && passwordState ==="success"){
    
    AuthService.login(username, password).then(
      ()=>{
        window.location.reload();
      }      
    )
    .catch((error) => { 
      
      var message = 'Неизвесная ошибка';
      var e = error;
      if(e.toString() !== 'Error: Network Error'){
              
          if(error.response.data.error.username){
             message = error.response.data.error.username['0'];
          }
          else if(error.response.data.error.password){
            message = error.response.data.error.password['0'];
          }
          else{
            message = error.response.data.error;
          }  
          
        }
        else{
          message = e.toString();
        }
      
      playMessage();
      setErrorMessage(message);
      showNotification("tc"); 
             
     }
    );
  }
  else if(usernameVerifyEmailState === false){ 
      playMessage(); 
      setErrorMessage("Неверный формат email");    
      showNotification("tc");
    }
  else{
      playMessage();
      setErrorMessage("Не заполнены обязательные поля");    
      showNotification("tc");
    }
}

const [playClick] = useSound(clickSound);
const [playMessage] = useSound(messageSound);
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={handleLogin}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="rose"
              >
                <h4 className={classes.cardTitle}>Авторизация</h4>
                
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Имя пользователя"
                  id="username"
                  success={usernameState === "success"}
                  error={usernameState === "error"}
                  formControlProps={{
                    fullWidth: true
                  }}
                  
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Face className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    
                    onChange:onChangeUsername
                    
                  }}
                />
           
                <CustomInput
                  labelText="Пароль"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  success={passwordState === "success"}
                  error={passwordState === "error"}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Icon className={classes.inputAdornmentIcon}>
                          lock_outline
                        </Icon>
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    
                    onChange:onChangePassword
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button 
                color="rose" 
                simple 
                size="lg" 
                block              
                type="submit"
                onClick = {playClick}
                >
                 Войти
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
      <Snackbar
                  place="tc"
                  color="rose"
                  icon={AddAlert}
                  message = {errorMessage}
                  open={tc}
                  closeNotification={() => setTC(false)}
                  close
                />
    </div>
  );
}
export default LoginPage;