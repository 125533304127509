import React from "react";
import {Redirect, Route} from "react-router-dom";

export const GuestRoute = ({ component: Component, ...rest}) => (
  
    
    <Route
        {...rest}
        render={ props => 
             localStorage.getItem("user") != null
           
            ? (
                <Redirect
                to={{
                    pathname : "/admin/dashborad",
                    state: {from: props.location}
                }}
            />
            ) : (
                <Component {...props} />
                
            )

        }
    />
);