import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";

// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";

import { dataTable } from "variables/general.js";

const codeExample = `import React from "react";
// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";

// core components
import Button from "components/CustomButtons/Button.js";
import ReactTable from "components/ReactTable/ReactTable.js";

import { dataTable } from "variables/general.js";

function ReactTables({ ...props }) {
  return (
      <ReactTable
        data={dataTable.dataRows.map((prop, key) => {
          return {
            id: key,
            name: prop[0],
            position: prop[1],
            office: prop[2],
            age: prop[3],
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to add a like kind of action */}
                <Button
                  justIcon
                  round
                  simple
                  onClick={() =>
                    alert("You've pressed the like button on colmun id: " + key)
                  }
                  color="info"
                  className="like"
                >
                  <Favorite />
                </Button>{" "}
                {/* use this button to add a edit kind of action */}
                <Button
                  justIcon
                  round
                  simple
                  onClick={() =>
                    alert("You've pressed the edit button on colmun id: " + key)
                  }
                  color="warning"
                  className="edit"
                >
                  <Dvr />
                </Button>{" "}
                {/* use this button to remove the data row */}
                <Button
                  justIcon
                  round
                  simple
                  onClick={() =>
                    alert(
                      "You've pressed the delete button on colmun id: " + key
                    )
                  }
                  color="danger"
                  className="remove"
                >
                  <Close />
                </Button>{" "}
              </div>
            )
          };
        })}
        columns={[
          {
            Header: "Name",
            accessor: "name"
          },
          {
            Header: "Position",
            accessor: "position"
          },
          {
            Header: "Office",
            accessor: "office"
          },
          {
            Header: "Age",
            accessor: "age"
          },
          {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      />
  );
}
export default ReactTables;
`;

function ReactTables({ ...props }) {
  return (
    <div>
      <h1>React Table v7.1.0</h1>
      <p>
        Hooks for building fast and extendable tables and datagrids for React.
      </p>
      <p>
        For details please refer to{" "}
        <a
          href="https://react-table.js.org/#/story/readme"
          target="_blank"
          rel="noopener noreferrer"
        >
          react-table official documentation
        </a>
        .
      </p>
      <h2>
        <code>NOTICE</code>
      </h2>
      <p>
        On the last column of this component we've added{" "}
        <code>text-align: left;</code> because we've considered the last column
        to be reserved for the actions of the table. If you wish to change this
        behavior you need to change the styling found in the file below.
      </p>
      <h2>
        <code>NOTICE</code>
      </h2>
      <p>
        The <code>components/ReactTable/ReactTable.js</code> is just a demo
        table, and it comes as is. We do not offer support for adding or
        deleting new code or the demo code. If you wish to add new hooks to our
        demo component, you can do so. Or, you can duplicate our component and
        create a new one based on our.
      </p>
      <p>
        For our demo component, we've used the following three examples from the
        react-table repository:
        <ul>
          <li>
            <a
              href="https://github.com/tannerlinsley/react-table/blob/master/docs/examples/simple.md?ref=creativetim#sorting"
              target="_blank" rel="noopener noreferrer"
            >
              Sorting
            </a>
          </li>
          <li>
            <a
              href="https://github.com/tannerlinsley/react-table/blob/master/docs/examples/simple.md?ref=creativetim#pagination"
              target="_blank" rel="noopener noreferrer"
            >
              Pagination
            </a>
          </li>
          <li>
            <a
              href="https://github.com/tannerlinsley/react-table/blob/master/docs/examples/simple.md?ref=creativetim#filtering"
              target="_blank" rel="noopener noreferrer"
            >
              Filtering
            </a>
          </li>
        </ul>
      </p>
      <h2>Styles</h2>
      <p>
        You will find the styles for this component in
        <br />{" "}
        <code>
          src/assets/scss/material-dashboard-pro-react/plugins/_plugin-react-table.scss
        </code>
        .
      </p>
      <h2>Example</h2>
      <ReactTable
        data={dataTable.dataRows.map((prop, key) => {
          return {
            id: key,
            name: prop[0],
            position: prop[1],
            office: prop[2],
            age: prop[3],
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to add a like kind of action */}
                <Button
                  justIcon
                  round
                  simple
                  onClick={() =>
                    alert("You've pressed the like button on colmun id: " + key)
                  }
                  color="info"
                  className="like"
                >
                  <Favorite />
                </Button>{" "}
                {/* use this button to add a edit kind of action */}
                <Button
                  justIcon
                  round
                  simple
                  onClick={() =>
                    alert("You've pressed the edit button on colmun id: " + key)
                  }
                  color="warning"
                  className="edit"
                >
                  <Dvr />
                </Button>{" "}
                {/* use this button to remove the data row */}
                <Button
                  justIcon
                  round
                  simple
                  onClick={() =>
                    alert(
                      "You've pressed the delete button on colmun id: " + key
                    )
                  }
                  color="danger"
                  className="remove"
                >
                  <Close />
                </Button>{" "}
              </div>
            )
          };
        })}
        columns={[
          {
            Header: "Name",
            accessor: "name"
          },
          {
            Header: "Position",
            accessor: "position"
          },
          {
            Header: "Office",
            accessor: "office"
          },
          {
            Header: "Age",
            accessor: "age"
          },
          {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false
          }
        ]}
      />
      <SyntaxHighlighter language="jsx" style={prism}>
        {codeExample}
      </SyntaxHighlighter>
    </div>
  );
}
export default ReactTables;
