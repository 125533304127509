import React,{useState} from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";

import Datetime from "react-datetime";
import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomInputDateTimeEdit(props) {

  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText,

  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error
  });
  let newInputProps = {
    maxLength:
      inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
    minLength:
      inputProps && inputProps.minLength ? inputProps.minLength : undefined,
    readOnly: true  
  };

  const [dobLblShrink, setDobLblShrinkState] = useState(true);
  const [dobLblShrinkClass, setDobLblShrinkClassState] = useState(" MuiInputLabel-shrink");
  const onOpenDateTime = (e)=>{
   
    setDobLblShrinkState(true);
    setDobLblShrinkClassState(" MuiInputLabel-shrink");
   };
  const onCloseDateTime = (e) =>{
   
       if(e === null || e ==="" ){
        setDobLblShrinkState(false);
        setDobLblShrinkClassState("");
       }
       else{
        setDobLblShrinkClassState(" MuiInputLabel-shrink MuiFormLabel-filled"); 
       } 
  }


  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + " " + labelClasses + dobLblShrinkClass}
          htmlFor={id}
          {...labelProps}
          data-shrink={dobLblShrink}
        >
          {labelText}
        </InputLabel>
      ) : null}
     
       <Datetime
          timeFormat={false}
          locale="ru"
          onOpen = {onOpenDateTime}
          onClose = {onCloseDateTime}
          closeOnSelect = {true}
          classes={{
            input: inputClasses,
            root: marginTop,
            disabled: classes.disabled,
            underline: underlineClasses
          }}
          id={id}
           {...inputProps}
          inputProps={
              newInputProps            
                           
            }
            
       />
      {helperText !== undefined ? (
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

CustomInputDateTimeEdit.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node
};
