import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Button from "components/CustomButtons/Button.js";
// styles for buttons on sweetalert
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const codeExample = `import React from 'react';
// react component used to create sweet alerts
import SweetAlert from "react-bootstrap-sweetalert";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Button from "components/CustomButtons/Button.js";
// styles for buttons on sweetalert
import styles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);

export default function SweetAlertDemo(){
  const classes = useStyles();
  const [alert,setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  }
  const successAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => this.hideAlert()}
        onCancel={() => this.hideAlert()}
        confirmBtnCssClass={
          classes.button + " " + classes.success
        }
      >
        You clicked the button!
      </SweetAlert>
    );
  }
  return (
    <div>
      {this.state.alert}
      <Button color="rose" onClick={this.successAlert.bind(this)}>
        Try me!
      </Button>
    </div>
  );
}`;

class SweetAlertDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null
    };
    this.hideAlert = this.hideAlert.bind(this);
  }
  hideAlert() {
    this.setState({
      alert: null
    });
  }
  successAlert() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Good job!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          You clicked the button!
        </SweetAlert>
      )
    });
  }
  render() {
    return (
      <div>
        <h1>React Bootstrap Sweetalert v5.1.9</h1>
        <p>
          If you want to replace the classic alert box with something that looks
          amazing, you can use the React Bootstrap Sweet Alert Plugin. We have
          changed the typography and colors for the plugin provided by{" "}
          <a
            href="https://github.com/djorg83"
            target="_blank"
            rel="noopener noreferrer"
          >
            Daniel Jorgensens
          </a>
          . If you want to see the full documentation, please check out{" "}
          <a
            href="https://github.com/djorg83/react-bootstrap-sweetalert"
            target="_blank"
            rel="noopener noreferrer"
          >
            this page
          </a>
          .
        </p>
        <h2>Styles</h2>
        <p>
          You will find the styles for this component in
          <br />{" "}
          <code>
            src/assets/scss/material-dashboard-pro-react/plugins/_plugin-react-bootstrap-sweetalert.scss
          </code>
          .
        </p>
        <h2>Example</h2>
        {this.state.alert}
        <Button color="rose" onClick={this.successAlert.bind(this)}>
          Try me!
        </Button>
        <SyntaxHighlighter language="jsx" style={prism}>
          {codeExample}
        </SyntaxHighlighter>
      </div>
    );
  }
}

export default withStyles(sweetAlertStyle)(SweetAlertDemo);
