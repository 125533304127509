import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import useSound from "use-sound";
import clickSound from "../../assets/sound/ui-click.wav";
import messageSound from "../../assets/sound/notification-up.wav";
import AdminService from "../../services/admin.service";
import Snackbar from "../Snackbar/Snackbar";
import {useHistory} from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import classNames from "classnames";
import CustomInputPhone from "../CustomInput/CustomInputPhone";


const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});
const AddMasterModal = (props) => {
    const classes = useStyles();
    const history = useHistory();
    // eslint-disable-next-line react/prop-types
    const {open, onClose} = props;
    const [successSelect, setSuccess] = useState(false);
    const [errorSelect, setError] = useState(false);
    const labelClasses = classNames({
        [" " + classes.labelRootError]: errorSelect,
        [" " + classes.labelRootSuccess]: successSelect && !errorSelect,
    });
    const underlineClasses = classNames({
        [classes.underlineError]: errorSelect,
        [classes.underlineSuccess]: successSelect && !errorSelect,
        [classes.underline]: true,
        //[classes.whiteUnderline]: white,
    });


    const [name, setName] = useState("");
    const [nameState, setNameState] = useState("");

    const [phone, setPhone] = useState("");
    const [phoneState, setPhoneState] = useState("");

    const [categoryData, setCategoryData] = useState([]);
    const [category, setCategory] = useState([]);
    //const [categoryState, setCategoryState] = useState("");

    const verifyLength = (value, length) => {
        return value.length >= length;
    };
    const verifyDigitsCount = (value, length) => {
        return value.replace(/[^0-9]/g, "").length === length;
    }
    const onChangeName = (e) => {
        if (verifyLength(e.target.value, 1)) {
            setNameState("success");
        } else {
            setNameState("error");
        }
        setName(e.target.value);
    };

    const onChangePhone = (e) => {
        if (verifyDigitsCount(e.target.value, 1)) {
            setPhoneState("default");
        } else if (verifyDigitsCount(e.target.value, 11)) {
            setPhoneState("success");
        } else {
            setPhoneState("error");
        }
        setPhone(e.target.value.replace(/[^0-9]/g, "").substring(1));

    };
    const onChangeCategory = (e) => {
        if (verifyLength(e.target.value, 1)) {
            setSuccess(true);
        } else {
            setError(true);
        }
        setCategory(e.target.value);
    };
    const [playClick] = useSound(clickSound);
    const [playMessage] = useSound(messageSound);
    const [notificationMessage, setMessage] = useState("");
    const [notificationBg, setMessageBg] = useState("warning");
    const [tr, setTR] = useState(false);
    const showNotification = () => {
        setTR(true);
        setTimeout(function () {
            setTR(false);
        }, 6000);
    };

    useEffect(() => {
        AdminService.getCategories()
            .then((response) => {
                const data = response.data.data.map((prop) => {
                    const item = prop.attributes;
                    return {
                        id: prop.id,
                        title: item.title,
                    };
                });
                setCategoryData(data);
            })
            .catch((error) => {
                alert(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleAddMaster = (e) => {
        e.preventDefault();

        if (name === "") {
            setNameState("error");
        }

        if (verifyDigitsCount(phone, 11) === false) {
            setPhoneState("error");
        }


        if (nameState === "success" && phoneState === "success") {
            var data = {
                type: "masters",
                attributes: {
                    name: name,
                    phone: phone,
                    category_id: JSON.stringify(category),
                },
            };

            AdminService.addMaster(data)
                .then((response) => {
                    setName("");
                    setNameState("default");
                    setPhone("");
                    setPhoneState("default");
                    playMessage();
                    setMessage("ок");
                    setMessageBg("success");
                    showNotification("tr");
                    history.push("/admin/masters/edit/" + response.data.data.id);
                })
                .catch((error) => {
                    alert(error);
                    console.log(error);
                });
        }
        else {
            playMessage();
            setMessage("Не заполнены обязательные поля");
            setMessageBg("rose");
            showNotification("tr");
        }
    };

    const categoriesList = categoryData.map((item) => {
        return <MenuItem
            classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelectedMultiple
            }}
            value={item.id}
            key={item.id}
        >
            {item.title}
        </MenuItem>
    })

    return (
        <Dialog
            classes={{
                root: classes.center + " " + classes.modalRoot,
                paper: classes.modal,
            }}
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="notice-modal-slide-title"
            aria-describedby="notice-modal-slide-description"
            fullWidth
        >
            <DialogTitle
                id="notice-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
                style={{textAlign: "left"}}
            >
                <span className={classes.cardTitle}>Добавить сотрудника</span>
                <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={onClose}
                >
                    <Close className={classes.modalClose}/>
                </Button>
            </DialogTitle>
            <DialogContent
                id="notice-modal-slide-description-2"
                className={classes.modalBody}
            >
                <CardBody>
                    <form onSubmit={handleAddMaster}>
                        <div style={{marginTop: "20px"}}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                        labelText="Имя"
                                        id="name"
                                        formControlProps={{
                                            fullWidth: true,
                                        }}
                                        success={nameState === "success"}
                                        error={nameState === "error"}
                                        default={nameState === "default"}
                                        inputProps={{
                                            autoComplete: "off",
                                            value: name,
                                            onChange: onChangeName,
                                        }}
                                    />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <FormControl fullWidth className={classes.formControl}>
                                        <InputLabel
                                            htmlFor="multiple-select"
                                            className={classes.labelRoot + " " + labelClasses}
                                            style={{marginTop: "5px"}}
                                        >
                                            Категория
                                        </InputLabel>
                                        <Select
                                            multiple
                                            className={underlineClasses}
                                            MenuProps={{
                                                className: classes.selectMenu,
                                            }}
                                            classes={{
                                                select: classes.select,
                                            }}
                                            value={category}
                                            onChange={onChangeCategory}
                                            inputProps={{
                                                name: "multipleSelect",
                                                id: "multiple-select",
                                            }}
                                        >
                                            <MenuItem
                                                disabled
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                }}
                                            >
                                                Категория
                                            </MenuItem>
                                            {categoriesList}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomInputPhone
                                        mask="+7(999)999-99-99"
                                        labelText="Телефон"
                                        id="phone"
                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                        onChange={onChangePhone}
                                        success={phoneState === "success"}
                                        error={phoneState === "error"}
                                        default={phoneState === "default"}
                                        inputProps={{
                                            value: phone
                                        }}
                                    />

                                </GridItem>
                            </GridContainer>
                            <Button color="rose" type="submit" onClick={playClick}>
                                Добавить услугу
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </DialogContent>
            <Snackbar
                place="tr"
                color={notificationBg}
                message={notificationMessage}
                open={tr}
                closeNotification={() => setTR(false)}
                close
            />
        </Dialog>
    );
};

export default AddMasterModal;
