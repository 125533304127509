import axios from "axios";

const register = (username, email, password) => {
  return axios.post(process.env.REACT_APP_API_URL + "register", {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .post(process.env.REACT_APP_API_URL + "login", {
      username,
      password,
    })
    .then((response) => {
      //console.log(response.data);
      if (response.data.accessToken) {
        localStorage.setItem("user", JSON.stringify(response.data));       
      }      
      return response.data;
    })
    
};

const logout = () => {
  localStorage.removeItem("user");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};





const unLock = (username,password) => {
  return axios
  .post(process.env.REACT_APP_API_URL + "unlock", {
    username,   
    password
  })
  .then((response) => {
    if (response.data.accessToken) {
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.removeItem("lockUserData");       
    }  
  });
};

export default {
  register,
  login,
  logout,
  unLock,
  getCurrentUser,
 
};