import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { prism } from "react-syntax-highlighter/dist/esm/styles/prism";

const codeExample = `import React from "react";
...

// core components
...
import Sidebar from "components/Sidebar/Sidebar.js";
...

import routes from "routes.js";

...

import image from "assets/img/sidebar-2.jpg";
import logo from "assets/img/logo-white.svg";

...

export default function YourCOmponentName(){
  ...
  const [mobileOpen,setMobileOpen] = React.useState(false);
  const [miniActive,setMiniActive] = React.useState(false);
  const [image,setImage] = React.useState(image);
  const [color,setColor] = React.useState("blue");
  const [bgColor,setBgColor] = React.useState("black");
  ...
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  ...
  return (
    ...
      <Sidebar
        routes={routes}
        logoText={"Creative Tim"}
        logo={logo}
        image={this.state.image}
        handleDrawerToggle={this.handleDrawerToggle}
        open={this.state.mobileOpen}
        color={this.state.color}
        bgColor={this.state.bgColor}
        miniActive={this.state.miniActive}
        {...rest}
      />
    ...
  );
}

...`;
const codeProps = `Sidebar.defaultProps = {
  bgColor: "blue"
};

Sidebar.propTypes = {
  bgColor: PropTypes.oneOf(["white", "black", "blue"]),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf(["white", "red", "orange", "green", "blue", "purple"]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object)
};`;

function Sidebar({ ...props }) {
  //  const { } = props;
  return (
    <div>
      <h1>Sidebar</h1>
      <p>
        This is the main navigation of the <code>Admin</code> layout. It's
        position is on left, and if you're on RTL it is on the right side of the
        window.
      </p>
      <p>
        To change our demo user, you need to change lines <code>134-221</code>.
      </p>
      <p>
        Between lines <code>31-55</code> there is a custom made component in
        which we render the links, links from the header (top navigation of our
        demo) and the user collapse. We've done this so that we can initialize
        and use <code>PerfectScrollbar</code> for Windows users.
      </p>
      <h2>Styles</h2>
      <p>
        You will find the styles for this component in
        <br />{" "}
        <code>
          src/assets/jss/material-dashboard-pro-react/components/sidebarStyle.js
        </code>
        .
      </p>
      <h2>Usage</h2>
      <SyntaxHighlighter language="jsx" style={prism}>
        {codeExample}
      </SyntaxHighlighter>
      <h2>Props</h2>
      <SyntaxHighlighter language="jsx" style={prism}>
        {codeProps}
      </SyntaxHighlighter>
    </div>
  );
}

export default Sidebar;
