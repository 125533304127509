import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/icons/List";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import AdminService from "services/admin.service";
import "moment/locale/ru";
import Preloader from "../../components/Preloader/preloader.js";
import useSound from "use-sound";
import clickSound from "../../assets/sound/ui-click.wav";
import messageSound from "../../assets/sound/notification-up.wav";
import Snackbar from "../../components/Snackbar/Snackbar";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import classNames from "classnames";
const useStyles = makeStyles(styles);

const EditServices = (props) => {
  const classes = useStyles();
  const {
    // eslint-disable-next-line react/prop-types
    params: { id },
  } = props.match;
  const [successSelect, setSuccess] = useState(false);
  const [errorSelect, setError] = useState(false);
  const labelClasses = classNames({
    [" " + classes.labelRootError]: errorSelect,
    [" " + classes.labelRootSuccess]: successSelect && !errorSelect,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: errorSelect,
    [classes.underlineSuccess]: successSelect && !errorSelect,
    [classes.underline]: true,
    //[classes.whiteUnderline]: white,
  });


  const [dataLoading, setDataLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [titleState, setTitleState] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionState, setDescriptionState] = useState("");
  const [price, setPrice] = useState("");
  const [priceState, setPriceState] = useState("");
  const [category, setCategory] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const verifyLength = (value, length) => {
    return value.length >= length;
    };

  const onChangeTitle = (e) => {
    if (verifyLength(e.target.value, 1)) {
      setTitleState("success");
    } else {
      setTitleState("error");
    }
    setTitle(e.target.value);
  };
  const onChangeDescription = (e) => {
    if (verifyLength(e.target.value, 1)) {
      setDescriptionState("success");
    }
    setDescription(e.target.value);
  };
  const onChangePrice = (e) => {
    if (verifyLength(e.target.value, 1)) {
      setPriceState("success");
    } else {
      setPriceState("error");
    }
    setPrice(e.target.value);
  };
  const onChangeCategory = (e) => {
    if (verifyLength(e.target.value, 1)) {
      setSuccess(true);
      //setCategoryState("success");
    } else {
      setError(true);
      //setCategoryState("error");
    }
    setCategory(e.target.value);
  };
  useEffect(() => {
    AdminService.getCategories()
        .then((response) => {
          const dataCat = response.data.data.map((prop) => {
            const item = prop.attributes;
            return {
              id: prop.id,
              title: item.title,
            };
          });
          setCategoryData(dataCat);
        })
        .catch((error) => {
          alert(error);
        });


    AdminService.getService(id)
      .then((response) => {
        if (response.data.data.attributes) {
          const service = response.data.data.attributes;

          setTitle(service.title ? service.title : "");
          setDescription(service.description ? service.description : "");
          setPrice(service.price ? service.price : "");
          setCategory(service.category_id ? service.category_id : "");
          setDataLoading(false);
        }
      })
      .catch((error) => {
        alert(error);
      });


  }, [id]);
  const [playClick] = useSound(clickSound);
  const [playMessage] = useSound(messageSound);
  const [notificationMessage, setMessage] = useState("");
  const [notificationBg, setMessageBg] = useState("warning");
  const [tr, setTR] = useState(false);
  const showNotification = () => {
    setTR(true);
    setTimeout(function () {
      setTR(false);
    }, 6000);
  };
  const handleUpdateService = (e) => {
    e.preventDefault();
    if (title === "") {
      setTitleState("error");
    }
    if (description === "") {
      setDescriptionState("error");
    }
    if (price === "") {
      setPriceState("error");
    }

    if (
      titleState !== "error" &&
      descriptionState !== "error" &&
      priceState !== "error"
    ) {
      // eslint-disable-next-line

      var data = {
        type: "services",
        id: id,
        attributes: {
          title: title,
          description: description,
          price: price,
          category_id: category,
        },
      };

      AdminService.updateService(data, id)
        .then(() => {
          setTitleState("default");
          setDescriptionState("default");
          setPriceState("default");
          playMessage();
          setMessage("Услуга изменена!");
          setMessageBg("success");
          showNotification("tr");
        })
        .catch((error) => {
          var message = error.toString();
          playMessage();
          setMessage(message);
          setMessageBg("danger");
          showNotification("tr");
        });
    }
  };


  const categoriesList = categoryData.map((item) => {
    return <MenuItem
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}
        value={item.id}
        key={item.id}
    >
      {item.title}
    </MenuItem>
  })


  return (
    <>
      {dataLoading ? (
        <Preloader />
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <List />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Услуга: {title}</h4>
              </CardHeader>

              <CardBody>
                <form onSubmit={handleUpdateService}>
                  <GridContainer>
                    <GridItem lg={6}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12} >
                          <CustomInput
                              labelText="Название"
                              id="title"
                              success={titleState === "success"}
                              error={titleState === "error"}
                              default={titleState === "default"}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                value: title,
                                onChange: onChangeTitle,
                              }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} >
                          <CustomInput
                              labelText="Описание"
                              id="description"
                              success={descriptionState === "success"}
                              error={descriptionState === "error"}
                              default={descriptionState === "default"}
                              formControlProps={{
                                fullWidth: true,
                              }}
                              inputProps={{
                                value: description,
                                onChange: onChangeDescription,
                              }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} >
                          <CustomInput
                              labelText="Цена"
                              id="price"
                              formControlProps={{
                                fullWidth: true,
                              }}
                              success={priceState === "success"}
                              error={priceState === "error"}
                              default={priceState === "default"}
                              inputProps={{
                                value: price,
                                onChange: onChangePrice,
                              }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} >
                          <FormControl fullWidth className={classes.formControl}>
                            <InputLabel
                                htmlFor="simple-select"
                                className={classes.labelRoot + " " + labelClasses}
                                style={{ marginTop: "5px" }}
                            >
                              Категория
                            </InputLabel>
                            <Select
                                className={underlineClasses}
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={category}
                                onChange={onChangeCategory}
                                inputProps={{
                                  name: "simpleSelect",
                                  id: "simple-select",
                                }}
                            >
                              <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                              >
                                Категория
                              </MenuItem>
                              {categoriesList}
                            </Select>
                          </FormControl>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                  <Button
                    color="rose"
                    className={classes.updateProfileButton}
                    onClick={playClick}
                    type="submit"
                  >
                    Сохранить
                  </Button>
                  <Clearfix />
                </form>
              </CardBody>
            </Card>
          </GridItem>
          <Snackbar
            place="tr"
            color={notificationBg}
            message={notificationMessage}
            open={tr}
            closeNotification={() => setTR(false)}
            close
          />
        </GridContainer>
      )}
    </>
  );
};

export default EditServices;
