/*eslint-disable*/
import React from "react";
// react components used to create a calendar with events on it
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import Toolbar from "react-big-calendar/lib/Toolbar";
// dependency plugin for react-big-calendar
import moment from "moment";
// react component used to create alerts
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
//import Heading from "components/Heading/Heading.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import styles from "assets/jss/material-dashboard-pro-react/components/buttonStyle.js";

import { events as calendarEvents } from "variables/general.js";

const localizer = momentLocalizer(moment);


const useStyles = makeStyles(styles);

export default function Calendar() {
  const classes = useStyles();
  const [events, setEvents] = React.useState(calendarEvents);
  const [alert, setAlert] = React.useState(null);

  const selectedEvent = event => {
    window.alert(event.title);
  };
  const addNewEventAlert = (slotInfo) => {
    setAlert(
      <SweetAlert
        input

        style={{ display: "block", marginTop: "-100px" }}
        title="Имя клиента"
        onConfirm={e => addNewEvent(e, slotInfo)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
      />
    );
  };
  const addNewEvent = (e, slotInfo) => {

    var colors = {
      1:'red',
      2:'azure',
      3:'orange',
      4:'green',
      5:'yellow',
      };

    var newEvents = events;
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end,
      resourceId: slotInfo.resourceId,
      color: colors[ slotInfo.resourceId],
    });
    setAlert(null);
    setEvents(newEvents);


  };
  const hideAlert = () => {
    setAlert(null);
  };
  const eventColors = event => {

    var backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor
    };
  };

  const resourceMap = [
    { resourceId: 1, resourceTitle: 'Гольда Меир'},
    { resourceId: 2, resourceTitle: 'Индира Ганди' },
    { resourceId: 3, resourceTitle: 'Жаклин Кеннеди' },
    { resourceId: 4, resourceTitle: 'Маргарет Тетчер' },
    { resourceId: 5, resourceTitle: 'Жанна Дарк' },

  ]
  return (
    <div>
      {/*<Heading*/}
      {/*  textAlign="center"*/}
      {/*  title="React Big Calendar"*/}
      {/*  category={*/}
      {/*    <span>*/}
      {/*      A beautiful react component made by{" "}*/}
      {/*      <a*/}
      {/*        href="https://github.com/intljusticemission?ref=creativetim"*/}
      {/*        target="_blank"*/}
      {/*      >*/}
      {/*        International Justice Mission*/}
      {/*      </a>*/}
      {/*      . Please checkout their{" "}*/}
      {/*      <a*/}
      {/*        href="https://github.com/intljusticemission/react-big-calendar?ref=creativetim"*/}
      {/*        target="_blank"*/}
      {/*      >*/}
      {/*        full documentation.*/}
      {/*      </a>*/}
      {/*    </span>*/}
      {/*  }*/}
      {/*/>*/}
      {alert}
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardBody calendar>
              <BigCalendar
                messages={{next:"вперед",previous:"назад",today:"сегодня",day:"день",month:"месяц",year:"год"}}
                selectable
                localizer={localizer}
                events={events}
                views={['day']}
                defaultView='day'
                scrollToTime={new Date(1970, 1, 1, 6)}
                defaultDate={new Date()}
                step={30}
                onSelectEvent={event => selectedEvent(event)}
                onSelectSlot={slotInfo => addNewEventAlert(slotInfo)}
                eventPropGetter={eventColors}
                resources={resourceMap}
                resourceIdAccessor="resourceId"
                resourceTitleAccessor="resourceTitle"
                showMultiDayTimes={true}
                min={new Date(1970,1,1,8)}
                //max={new Date(2021,4,7,23)}

              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
