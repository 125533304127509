import axios from "axios";
import authHeader from "./auth-header";
import authHeaderMD from "./auth-header-md";
//clients
const getClients = () => {
  return axios.get(process.env.REACT_APP_API_URL + "clients", {
    headers: authHeader(),
  });
};
const getClient = (clientId) => {
  return axios.get(process.env.REACT_APP_API_URL + "clients/" + clientId, {
    headers: authHeader(),
  });
};
const addClient = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "clients",
    { data },
    { headers: authHeader() }
  );
};
const deleteClient = (clientId) => {
  return axios.delete(process.env.REACT_APP_API_URL + "clients/" + clientId, {
    headers: authHeader(),
  });
};

const updateClientProfile = (data, clientId) => {
  return axios.patch(
    process.env.REACT_APP_API_URL + "clients/" + clientId,
    { data },
    { headers: authHeader() }
  );
};
const updateClientAva = (clientId, photo) => {
  var formData = new FormData();
  formData.append("photo", photo);
  return axios.post(
    process.env.REACT_APP_API_URL + "client/" + clientId + "/update_ava",
    formData,
    { headers: authHeaderMD() }
  );
};
//admin
const updateProfile = (userId, name, email, phone) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "profile/update",
    {
      userId,
      name,
      email,
      phone,
    },
    { headers: authHeader() }
  );
};

const updatePassword = (userId, newPassword, newPasswordConfirm) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "profile/update_password",
    {
      userId,
      newPassword,
      newPasswordConfirm,
    },
    { headers: authHeader() }
  );
};

const updateAva = (userId, photo) => {
  var formData = new FormData();
  formData.append("photo", photo);
  return axios.post(
    process.env.REACT_APP_API_URL + "profile/" + userId + "/update_ava",
    formData,
    { headers: authHeaderMD() }
  );
};
//services
const getServices = () => {
  return axios.get(process.env.REACT_APP_API_URL + "services", {
    headers: authHeader(),
  });
};
const addService = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "services",
    { data },
    { headers: authHeader() }
  );
};
const getService = (id) => {
  return axios.get(process.env.REACT_APP_API_URL + "services/" + id, {
    headers: authHeader(),
  });
};
const updateService = (data, id) => {
  return axios.patch(
    process.env.REACT_APP_API_URL + "services/" + id,
    { data },
    { headers: authHeader() }
  );
};
const deleteService = (id) => {
  return axios.delete(process.env.REACT_APP_API_URL + "services/" + id, {
    headers: authHeader(),
  });
};
//masters
const getMasters = () => {
  return axios.get(process.env.REACT_APP_API_URL + "masters", {
    headers: authHeader(),
  });
};
const addMaster = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "masters",
    { data },
    { headers: authHeader() }
  );
};
const getMaster = (id) => {
  return axios.get(process.env.REACT_APP_API_URL + "masters/" + id, {
    headers: authHeader(),
  });
};
const updateMaster = (data, id) => {
  return axios.patch(
    process.env.REACT_APP_API_URL + "masters/" + id,
    { data },
    { headers: authHeader() }
  );
};
const deleteMaster = (id) => {
  return axios.delete(process.env.REACT_APP_API_URL + "masters/" + id, {
    headers: authHeader(),
  });
};
//categories
const getCategories = () => {
  return axios.get(process.env.REACT_APP_API_URL + "categories", {
    headers: authHeader(),
  });
};
const addCategory = (data) => {
  return axios.post(
    process.env.REACT_APP_API_URL + "categories",
    { data },
    { headers: authHeader() }
  );
};
const getCategory = (id) => {
  return axios.get(process.env.REACT_APP_API_URL + "categories/" + id, {
    headers: authHeader(),
  });
};
const updateCategory = (data, id) => {
  return axios.patch(
    process.env.REACT_APP_API_URL + "categories/" + id,
    { data },
    { headers: authHeader() }
  );
};
const deleteCategory = (id) => {
  return axios.delete(process.env.REACT_APP_API_URL + "categories/" + id, {
    headers: authHeader(),
  });
};
export default {
  getClients,
  getClient,
  addClient,
  deleteClient,
  updateClientProfile,
  updateClientAva,
  updatePassword,
  updateProfile,
  updateAva,
  getServices,
  addService,
  updateService,
  getService,
  deleteService,
  getMasters,
  addMaster,
  getMaster,
  updateMaster,
  deleteMaster,
  getCategories,
  addCategory,
  updateCategory,
  getCategory,
  deleteCategory,
};
