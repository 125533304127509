import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/icons/List";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import AdminService from "services/admin.service";
import "moment/locale/ru";
import Preloader from "../../components/Preloader/preloader.js";
import useSound from "use-sound";
import clickSound from "../../assets/sound/ui-click.wav";
import messageSound from "../../assets/sound/notification-up.wav";
import Snackbar from "../../components/Snackbar/Snackbar";

const useStyles = makeStyles(styles);

const EditCategories = (props) => {
  const classes = useStyles();
  const {
    // eslint-disable-next-line react/prop-types
    params: { id },
    // eslint-disable-next-line react/prop-types
  } = props.match;

  const [dataLoading, setDataLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [titleState, setTitleState] = useState("");
  const [color, setColor] = useState("");
  const [colorState, setColorState] = useState("");
  const verifyLength = (value, length) => {
    return value.length >= length;
  };
  const onChangeTitle = (e) => {
    if (verifyLength(e.target.value, 1)) {
      setTitleState("success");
    } else {
      setTitleState("error");
    }
    setTitle(e.target.value);
  };
  const onChangeColor = (e) => {
    if (verifyLength(e.target.value, 6)) {
      setColorState("success");
    }
    setColor(e.target.value);
  };
  useEffect(() => {
    AdminService.getCategory(id)
      .then((response) => {
        if (response.data.data.attributes) {
          const category = response.data.data.attributes;
          setTitle(category.title ? category.title : "");
          setColor(category.color ? category.color : "");
          setDataLoading(false);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }, [id]);
  const [playClick] = useSound(clickSound);
  const [playMessage] = useSound(messageSound);
  const [notificationMessage, setMessage] = useState("");
  const [notificationBg, setMessageBg] = useState("warning");
  const [tr, setTR] = useState(false);
  const showNotification = () => {
    setTR(true);
    setTimeout(function () {
      setTR(false);
    }, 6000);
  };
  const handleUpdateCategory = (e) => {
    e.preventDefault();
    if (title === "") {
      setTitleState("error");
    }
    if (color === "") {
      setColorState("error");
    }
    if (titleState !== "error" && color !== "error") {
      // eslint-disable-next-line

      var data = {
        type: "categories",
        id: id,
        attributes: {
          title: title,
          color: color,
        },
      };

      AdminService.updateCategory(data, id)
        .then(() => {
          setTitleState("default");
          setColorState("default");
          playMessage();
          setMessage("Категория изменена!");
          setMessageBg("success");
          showNotification("tr");
        })
        .catch((error) => {
          var message = error.toString();
          playMessage();
          setMessage(message);
          setMessageBg("danger");
          showNotification("tr");
        });
    }
  };

  return (
    <>
      {dataLoading ? (
        <Preloader />
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <List />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>Категория: {title}</h4>
              </CardHeader>

              <CardBody>
                <form onSubmit={handleUpdateCategory}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <CustomInput
                        labelText="Название"
                        id="title"
                        success={titleState === "success"}
                        error={titleState === "error"}
                        default={titleState === "default"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: title,
                          onChange: onChangeTitle,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={6}>
                      <CustomInput
                        labelText="Цвет"
                        id="color"
                        success={colorState === "success"}
                        error={colorState === "error"}
                        default={colorState === "default"}
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          value: color,
                          onChange: onChangeColor,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <Button
                    color="rose"
                    className={classes.updateProfileButton}
                    onClick={playClick}
                    type="submit"
                  >
                    Сохранить
                  </Button>
                  <Clearfix />
                </form>
              </CardBody>
            </Card>
          </GridItem>
          <Snackbar
            place="tr"
            color={notificationBg}
            message={notificationMessage}
            open={tr}
            closeNotification={() => setTR(false)}
            close
          />
        </GridContainer>
      )}
    </>
  );
};

export default EditCategories;
