import React, { useState } from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import AuthService from "services/auth.service";
import Pusher from "pusher-js";
import Snackbar from "components/Snackbar/Snackbar";
//import useSound from "use-sound";
//import messageSound from "assets/sound/notification-up.wav";
import messagePusherSound from "assets/sound/notification-pusher.mp3";

var ps;

const useStyles = makeStyles(styles);

export default function Dashboard(props) {
  const { ...rest } = props;
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);
  const [image] = React.useState(require("assets/img/sidebar-3.jpg"));
  const [color] = React.useState("rose");
  const [bgColor] = React.useState("black");
  // const [hasImage, setHasImage] = React.useState(true);
  //const [fixedClasses, setFixedClasses] = React.useState("dropdown");
  const [logo] = React.useState(require("assets/img/logo-white.svg"));

  const [authUser] = React.useState(AuthService.getCurrentUser());
  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1,
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  if (localStorage.getItem("notificationDataStorage") === null) {
    localStorage.setItem("notificationDataStorage", JSON.stringify([]));
  }

  var [notificationCount, setCount] = useState(
    JSON.parse(localStorage.getItem("notificationDataStorage")).length
  );

  const [notificationMessage, setMessage] = useState("");
  const [notificationBg, setMessageBg] = useState("warning");
  const [tr, setTR] = useState(false);
  const showNotification = () => {
    setTR(true);
    setTimeout(function () {
      setTR(false);
    }, 20000);
  };
  const pusher = new Pusher("53171171137429d02639", {
    cluster: "eu",
    encrypted: true,
  });

  const channel = pusher.subscribe("daily-event");
  const sound = new Audio(messagePusherSound);

  const storageDataNT = JSON.parse(
    localStorage.getItem("notificationDataStorage")
  );
  const [pusherNotifications] = useState(
    storageDataNT.sort((a, b) => b.date.localeCompare(a.date))
  );

  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    channel.bind("App\\Events\\DailyEvent", function (data) {
      setCount((notificationCount) => notificationCount + 1);
      const notificationData = JSON.parse(data.message);
      if (notificationData.type === "birthday") {
        setMessage(notificationData.text + notificationData.name + "\n");
        setMessageBg("primary");
      }

      var notificationDataStorage = JSON.parse(
        localStorage.getItem("notificationDataStorage")
      );
      notificationDataStorage.push(notificationData);
      notificationDataStorage.sort((a, b) => b.date.localeCompare(a.date));
      localStorage.setItem(
        "notificationDataStorage",
        JSON.stringify(notificationDataStorage)
      );

      pusherNotifications.push(notificationData);
      pusherNotifications.sort((a, b) => b.date.localeCompare(a.date));
      sound.play();
      showNotification();
    });

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
    // eslint-disable-next-line
  },[]);
  // functions for changeing the states from components
  // const handleImageClick = image => {
  //   setImage(image);
  // };
  // const handleColorClick = color => {
  //   setColor(color);
  // };
  // const handleBgColorClick = bgColor => {
  //   switch (bgColor) {
  //     case "white":
  //       setLogo(require("assets/img/logo.svg"));
  //       break;
  //     default:
  //       setLogo(require("assets/img/logo-white.svg"));
  //       break;
  //   }
  //   setBgColor(bgColor);
  // };
  // const handleFixedClick = () => {
  //   if (fixedClasses === "dropdown") {
  //     setFixedClasses("dropdown show");
  //   } else {
  //     setFixedClasses("dropdown");
  //   }
  // };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {

    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          // console.log(routes[i]);
          return routes[i]; //.name;
        }
      }
    }

    return activeRoute;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
      }
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      if (prop.layout === "/admin") {
        //alert(prop.layout + prop.path);

        return (
          <Route
            exact //add exact for nested routes
            path={prop.layout + prop.path}
            component={prop.component}
            name={prop.name}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={routes}
        logoText={"Sun Plaza"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={color}
        bgColor={bgColor}
        miniActive={miniActive}
        authUser={authUser}
        notificationCount={notificationCount}
        pusherNotifications={pusherNotifications}
        {...rest}
      />
      <div className={mainPanelClasses} ref={mainPanel}>
        <AdminNavbar
          sidebarMinimize={sidebarMinimize.bind(this)}
          miniActive={miniActive}
          activeRoute={getActiveRoute(routes)}
          handleDrawerToggle={handleDrawerToggle}
          notificationCount={notificationCount}
          pusherNotifications={pusherNotifications}
          {...rest}
        />
        {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
        {getRoute() ? (
          <div className={classes.content}>
            <div className={classes.container}>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/admin" to="/admin/dashboard" />
              </Switch>
            </div>
          </div>
        ) : (
          <div className={classes.map}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/admin" to="/admin/dashboard" />
            </Switch>
          </div>
        )}
        {getRoute() ? <Footer fluid /> : null}
      </div>

      <Snackbar
        place="tr"
        color={notificationBg}
        message={notificationMessage}
        open={tr}
        closeNotification={() => setTR(false)}
        close
      />
    </div>
  );
}
