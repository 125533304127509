import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

//import Checkbox from "@material-ui/core/Checkbox";

// material-ui icons
//import Assignment from "@material-ui/icons/Assignment";
import Person from "@material-ui/icons/Person";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Group from "@material-ui/icons/Group";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { useHistory } from "react-router-dom";
import AdminService from "services/admin.service";
import ReactTable from "components/ReactTable/ReactTable.js";

import ClientModal from "components/Clients/ClientItemModal";
import AddClientModal from "components/Clients/AddClientModal";
import Moment from "react-moment";

import randomColor from "randomcolor";
import useSound from "use-sound";
import clickSound from "../../assets/sound/ui-click.wav";
import messageSound from "../../assets/sound/notification-up.wav";
//import Snackbar from "../../components/Snackbar/Snackbar";
import SweetAlert from "react-bootstrap-sweetalert";

const useStyles = makeStyles(styles);

// eslint-disable-next-line no-unused-vars
const Clients = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [open, setNoticeModal] = useState(false);
  const [openAcm, setAddClientModal] = useState(false);
  const [clientId, setClientId] = useState();
  const [clientName, setClientName] = useState("");
  const [clientPhone, setClientPhone] = useState();
  const [clientSocials, setClientSocials] = useState({});
  const [tableLoading, setTableLoading] = useState(true);
  const [avaColor, setAvaColor] = useState();
  const [clientAva, setClientAva] = useState("");

  const handleCloseModal = () => {
    setNoticeModal(false);
    setAddClientModal(false);
  };

  const [playClick] = useSound(clickSound);
  const [playMessage] = useSound(messageSound);
  const [alert, setAlert] = useState(null);
  const hideAlert = () => {
    setAlert(null);
  };
  const [clients, setClients] = useState([]);



useEffect(() => {
    AdminService.getClients()
      .then((response) => {
          const data = response.data.data.map((prop) => {
          setTableLoading(false);

          const item = prop.attributes;

                return {
                    id: item.clientId,
                    name: item.name,
                    phone: item.phone,
                    dob: <Moment format="DD.MM.YYYY">{item.dob}</Moment>,
                    actions: (
                        <div style={{textAlign: "center"}}>
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    playClick();
                                    setClientId(item.clientId);
                                    setClientName(item.name);
                                    setClientPhone(item.phone);
                                    setClientSocials(JSON.parse(item.socials));
                                    setNoticeModal(true);
                                    setAvaColor(randomColor());
                                    setClientAva(item.ava);
                                }}
                                color="info"
                                className={classes.actionButton}
                            >
                                <Person/>
                            </Button>{" "}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    playClick();
                                    history.push("/admin/clients/edit/" + item.clientId);
                                }}
                                color="success"
                                className={classes.actionButton}
                            >
                                <Edit/>
                            </Button>{" "}
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {

                                    playClick();
                                      setAlert(
                                        <SweetAlert
                                          warning
                                          style={{ display: "block", marginTop: "-100px" }}
                                          title="Вы уверены?"
                                          onConfirm={() =>
                                            AdminService.deleteClient(item.clientId)
                                              .then((response) => {
                                                if (response.status === 204) {
                                                  playMessage();
                                                  setAlert(
                                                    <SweetAlert
                                                      success
                                                      style={{
                                                        display: "block",
                                                        marginTop: "-100px",
                                                      }}
                                                      title="Удалили успешно!"
                                                      onConfirm={() => hideAlert()}
                                                      onCancel={() => hideAlert()}
                                                      confirmBtnCssClass={
                                                        classes.btnSuccess
                                                      }
                                                    ></SweetAlert>
                                                  );
                                                    var newData = data;
                                                    newData.find((o, i) => {
                                                        if (o.id === item.clientId) {
                                                            newData.splice(i, 1);
                                                            return true;
                                                        }
                                                        return false;
                                                    });
                                                    setClients([...newData]);
                                                }
                                              })
                                              .catch((error) => {
                                                alert(error);
                                              })
                                          }
                                          onCancel={() => hideAlert()}
                                          confirmBtnCssClass={
                                              classes.btnSuccess
                                          }
                                          cancelBtnCssClass={
                                              classes.btnRose
                                          }
                                          confirmBtnText="Да, давай удалим его!"
                                          cancelBtnText="Нет"
                                          showCancel
                                        >
                                          Будет удален клиент {item.name} <br />
                                          Будет удалена вся история клиента
                                        </SweetAlert>
                                      );
                                }
                                }
                                color="danger"
                                className={classes.actionButton}
                            >
                                <Close/>
                            </Button>{" "}
                        </div>
                    ),
                };
            })

            setClients(data);

        })
        .catch((error) => {
            setTableLoading(false);
            alert(error);
        })
 }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Group />
            </CardIcon>
            <div className={classes.addButtonContainer}>
              <Button
                onClick={() => {
                  setAddClientModal(true);
                }}
                color="primary"
              >
                Добавить клиента
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <hr />
            {
              <ReactTable
                loading={tableLoading}
                columns={[
                  {
                    Header: "Имя",
                    accessor: "name",
                  },
                  {
                    Header: "Телефон",
                    accessor: "phone",
                  },
                  {
                    Header: "Дата рождения",
                    accessor: "dob",
                    sortType: (a, b) => {
                      var a1 = new Date(a.values.dob.props.children).getTime();
                      var b1 = new Date(b.values.dob.props.children).getTime();
                      if (a1 < b1) return -1;
                      else if (a1 > b1) return 1;
                      else return 0;
                    },
                  },

                  {
                    Header: () => (
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        Действия
                      </div>
                    ),
                    accessor: "actions",
                  },
                ]}
                data={clients}
              />
            }

            <ClientModal
              clientId={clientId}
              clientName={clientName}
              clientPhone={clientPhone}
              clientSocials={clientSocials}
              clientAva={clientAva}
              avaColor={avaColor}
              open={open}
              onClose={handleCloseModal}
            />
            <AddClientModal open={openAcm} onClose={handleCloseModal} />
          </CardBody>
        </Card>
      </GridItem>
      {alert}
    </GridContainer>
  );
};

export default Clients;
