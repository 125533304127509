import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter, Switch,Redirect,Route } from "react-router-dom";
import { PrivateRoute} from "PrivateRoute";
import { GuestRoute} from "GuestRoute";
import { LockRoute} from "LockRoute";
import AuthLayout from "layouts/Auth.js";
////import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import Documentation from "layouts/Documentation/Documentation.js";

import "assets/scss/material-dashboard-pro-react.scss?v=1.9.0";


ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* <Route path="/rtl" component={RtlLayout} /> */}
      <GuestRoute path="/auth/login" component={AuthLayout} />
      <PrivateRoute  path="/admin" component={AdminLayout}/>           
      <LockRoute path="/auth/lock" component={AuthLayout} />
      <Route path="/documentation" component={Documentation} />
      <Redirect from="/" to="/admin/dashboard" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
