import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import List from "@material-ui/icons/List";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import { useHistory } from "react-router-dom";
import AdminService from "services/admin.service";
import ReactTable from "components/ReactTable/ReactTable.js";
import AddServiceModal from "../../components/Services/AddServiceModal";
import useSound from "use-sound";
import clickSound from "../../assets/sound/ui-click.wav";
import messageSound from "../../assets/sound/notification-up.wav";
import SweetAlert from "react-bootstrap-sweetalert";

const useStyles = makeStyles(styles);

// eslint-disable-next-line no-unused-vars
const Services = () => {
  const classes = useStyles();
  const history = useHistory();
  const [openAsm, setAddServiceModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const handleCloseModal = () => {
    setAddServiceModal(false);
  };
  const [playClick] = useSound(clickSound);
  const [playMessage] = useSound(messageSound);
  const [alert, setAlert] = useState(null);
  const hideAlert = () => {
    setAlert(null);
  };
  const [services, setServices] = useState([]);

  useEffect(() => {
    AdminService.getServices()
      .then((response) => {
        if (response.data.data.length === 0) {
          setTableLoading(false);
        }

        const data = response.data.data.map((prop) => {
          setTableLoading(false);

          const item = prop.attributes;

          return {
            id: item.serviceId,
            title: item.title,
            description: item.description,
            price: item.price,
            category: item.category,
            actions: (
              <div style={{ textAlign: "center" }}>
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    playClick();
                    history.push("/admin/services/edit/" + item.serviceId);
                  }}
                  color="success"
                  className={classes.actionButton}
                >
                  <Edit />
                </Button>{" "}
                <Button
                  justIcon
                  round
                  simple
                  onClick={() => {
                    playClick();
                    setAlert(
                      <SweetAlert
                        warning
                        style={{ display: "block", marginTop: "-100px" }}
                        title="Вы уверены?"
                        onConfirm={() =>
                          AdminService.deleteService(item.serviceId)
                            .then((response) => {
                              if (response.status === 204) {
                                playMessage();
                                setAlert(
                                  <SweetAlert
                                    success
                                    style={{
                                      display: "block",
                                      marginTop: "-100px",
                                    }}
                                    title="Удалили успешно!"
                                    onConfirm={() => hideAlert()}
                                    onCancel={() => hideAlert()}
                                    confirmBtnCssClass={classes.btnSuccess}
                                  ></SweetAlert>
                                );
                                var newData = data;
                                newData.find((o, i) => {
                                  if (o.id === item.serviceId) {
                                    newData.splice(i, 1);
                                    return true;
                                  }
                                  return false;
                                });
                                setServices([...newData]);
                              }
                            })
                            .catch((error) => {
                              alert(error);
                            })
                        }
                        onCancel={() => hideAlert()}
                        confirmBtnCssClass={classes.btnSuccess}
                        cancelBtnCssClass={classes.btnRose}
                        confirmBtnText="Да, давай удалим!"
                        cancelBtnText="Нет"
                        showCancel
                      >
                        Будет удалена услуга {item.title}
                      </SweetAlert>
                    );
                  }}
                  color="danger"
                  className={classes.actionButton}
                >
                  <Close />
                </Button>{" "}
              </div>
            ),
          };
        });

        setServices(data);
      })
      .catch((error) => {
        setTableLoading(false);
        console.log(error);
      });
    }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <List />
            </CardIcon>
            <div className={classes.addButtonContainer}>
              <Button
                onClick={() => {
                  setAddServiceModal(true);
                }}
                color="primary"
              >
                Добавить услугу
              </Button>
            </div>
          </CardHeader>
          <CardBody>
            <hr />

            {
              <ReactTable
                loading={tableLoading}
                columns={[
                  {
                    Header: "Название",
                    accessor: "title",
                  },
                  {
                    Header: "Описание",
                    accessor: "description",
                  },
                  {
                    Header: "Цены",
                    accessor: "price",
                  },
                  {
                    Header: "Категория",
                    accessor: "category",
                  },

                  {
                    // eslint-disable-next-line react/display-name
                    Header: () => (
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        Действия
                      </div>
                    ),
                    accessor: "actions",
                  },
                ]}
                data={services}
              />
            }

            <AddServiceModal open={openAsm} onClose={handleCloseModal} />
          </CardBody>
        </Card>
      </GridItem>
      {alert}
    </GridContainer>
  );
};

export default Services;
