import React from "react";
// react plugin for creating charts
import ChartistGraph from "react-chartist";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

import { straightLinesChart, simpleBarChart } from "variables/charts.js";

import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";

const useStyles = makeStyles(styles);

export default function CardChart() {
  const classes = useStyles();
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={6}>
        <Card chart>
          <CardHeader color="warning">
            <ChartistGraph
              className="ct-chart-white-colors"
              data={straightLinesChart.data}
              type="Line"
              options={straightLinesChart.options}
              listener={straightLinesChart.animation}
            />
          </CardHeader>
          <CardBody>
            <h4 className={classes.cardTitle}>Straight Lines Chart</h4>
            <p className={classes.cardCategory}>Line Chart with Points</p>
          </CardBody>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={12} md={6}>
        <Card chart>
          <CardHeader color="info">
            <ChartistGraph
              className="ct-chart-white-colors"
              data={simpleBarChart.data}
              type="Bar"
              options={simpleBarChart.options}
              responsiveOptions={simpleBarChart.responsiveOptions}
              listener={simpleBarChart.animation}
            />
          </CardHeader>
          <CardBody>
            <h4 className={classes.cardTitle}>Simple Bar Chart</h4>
            <p className={classes.cardCategory}>Bar Chart</p>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
