import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "components/CustomButtons/Button.js";
import Close from "@material-ui/icons/Close";
import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import useSound from "use-sound";
import clickSound from "../../assets/sound/ui-click.wav";
import messageSound from "../../assets/sound/notification-up.wav";
import AdminService from "../../services/admin.service";
import Snackbar from "../Snackbar/Snackbar";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles(styles);
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const AddCategoryModal = (props) => {
  const classes = useStyles();
  const history = useHistory();
  // eslint-disable-next-line react/prop-types
  const { open, onClose } = props;
  const [title, setTitle] = useState("");
  const [titleState, setTitleState] = useState("");
  const [color, setColor] = useState("");
  const [colorState, setColorState] = useState("");
  const verifyLength = (value, length) => {
    return value.length >= length;
  };
  const onChangeTitle = (e) => {
    if (verifyLength(e.target.value, 1)) {
      setTitleState("success");
    } else {
      setTitleState("error");
    }
    setTitle(e.target.value);
  };
  const onChangeColor = (e) => {
    setColorState("success");
    setColor(e.target.value);
  };
  const [playClick] = useSound(clickSound);
  const [playMessage] = useSound(messageSound);
  const [notificationMessage, setMessage] = useState("");
  const [notificationBg, setMessageBg] = useState("warning");
  const [tr, setTR] = useState(false);
  const showNotification = () => {
    setTR(true);
    setTimeout(function () {
      setTR(false);
    }, 6000);
  };
  const handleAddCategory = (e) => {
    e.preventDefault();

    if (title === "") {
      setTitleState("error");
    }

    if (titleState === "success" && colorState === "success") {
      var data = {
        type: "categories",
        attributes: {
          title: title,
          color: color,
        },
      };

      AdminService.addCategory(data)
        .then((response) => {
          setTitle("");
          setTitleState("default");
          setColor("");
          setColorState("default");
          playMessage();
          setMessage("ок");
          setMessageBg("success");
          showNotification("tr");
          history.push("/admin/categories/edit/" + response.data.data.id);
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      playMessage();
      setMessage("Не заполнены обязательные поля");
      setMessageBg("rose");
      showNotification("tr");
    }
  };

  return (
    <Dialog
      classes={{
        root: classes.center + " " + classes.modalRoot,
        paper: classes.modal,
      }}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="notice-modal-slide-title"
      aria-describedby="notice-modal-slide-description"
      fullWidth
    >
      <DialogTitle
        id="notice-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
        style={{ textAlign: "left" }}
      >
        <span className={classes.cardTitle}>Добавить категорию</span>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={onClose}
        >
          <Close className={classes.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent
        id="notice-modal-slide-description-2"
        className={classes.modalBody}
      >
        <CardBody>
          <form onSubmit={handleAddCategory}>
            <div style={{ marginTop: "20px" }}>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Название"
                    id="title"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    success={titleState === "success"}
                    error={titleState === "error"}
                    default={titleState === "default"}
                    inputProps={{
                      autoComplete: "off",
                      value: title,
                      onChange: onChangeTitle,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                  <CustomInput
                    labelText="Цвет"
                    id="color"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    success={colorState === "success"}
                    error={colorState === "error"}
                    default={colorState === "default"}
                    inputProps={{
                      autoComplete: "off",
                      value: color,
                      onChange: onChangeColor,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <Button color="rose" type="submit" onClick={playClick}>
                Добавить категорию
              </Button>
            </div>
          </form>
        </CardBody>
      </DialogContent>
      <Snackbar
        place="tr"
        color={notificationBg}
        message={notificationMessage}
        open={tr}
        closeNotification={() => setTR(false)}
        close
      />
    </Dialog>
  );
};

export default AddCategoryModal;
